// 05/2023
export const TwentyTwentyThreeAug = {
  viureview: {
    totalUsersQuantity: '276.649', // André
    moviePercent: 70,
    seriePercent: 30,
    usersQuantity: '17.586',
    usersReturn: 73, // André
    recommendationsQuantity: '1.593.817',
    likedQuantity: '39.891',
    unlikedQuantity: '7.848',
    notTodayQuantity: '10.308',
    notForeverQuantity: 2.028,
    mostRecommendedTitles: [
      { title: 'Ataque dos Cães (Netflix)', enable: true },
      { title: 'O Caso Collini (Netflix)', enable: true },
      { title: 'Osmosis (Netflix)', enable: true },
      { title: 'American Son (Netflix)', enable: true },
      { title: 'Entre Facas e Segredos (Prime Video)', enable: true },
      { title: '13 Reasons Why (Netflix)', enable: true },
      { title: 'Buscando... (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Good Girls (Netflix)', enable: true },
      { title: 'Areia Movediça (Netflix)', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Linha Reta (Star+)', enable: true },
      { title: 'A Linha Vermelha do Destino (Star+)', enable: true },
      { title: 'Se eu não tivesse te conhecido (Netflix)', enable: true },
      { title: 'Segurança em Jogo (Netflix)', enable: true },
      { title: 'Estrada para a Glória (Disney)', enable: true },
      { title: 'Mytho (Netflix)', enable: true },
      { title: 'Roman J. Israel, Esq. (Google Play)', enable: true },
      { title: 'Por que as Mulheres Matam - 1ª Temporada (Globoplay)', enable: true },
      { title: 'Fique Rico ou Morra Tentando (Netflix)', enable: true },
      { title: 'Diga quem sou (Netflix)', enable: true },
    ],
    charts: {
      hex: '#F36300',
      rgb: '243,99,0',
      performance: { // André
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022','JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [169752, 175281, 182088, 187931, 196814, 206173, 217630, 233719, 249809, 251977, 264916, 276649]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Ficção','Comédia'],
        values: [35,18,17,15,9]
      },
      subgenresChart: {
        titles: ['Relacoes','Crime','Investigacao','Livro','Psicologico','Real','Europa','Tecnologia','Familia','Oscar'],
        values: [8,7,6,5,5,4,4,4,3,3]
      },
      usersByStreaming: {
        titles: ['NETFLIX', 'PRIME VIDEO', 'HBO Max', 'TELECINE', 'APPLE TV', 'DISNEY+', 'STAR+', 'FILME FILME', 'GLOBOPLAY', 'GOOGLE PLAY'],
        values: [80, 58, 32, 21, 20, 14, 13, 12, 12, 6]
      }
    }
  },
  netflix: {
    id: 27,
    comercialName: 'Netflix',
    price: 39.9,
    moviePercent: 29,
    seriePercent: 45,
    usersQuantity: '14.241',
    usersReturn: 69, //falta
    recommendationsQuantity: '638.294',
    likedQuantity: '22.974',
    unlikedQuantity: '4.593',
    notTodayQuantity: '1.953',
    notForeverQuantity: 864,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '568.215,90',
    mostRecommendedTitles: [
      { title: 'Ataque dos Cães', enable: true },
      { title: 'O Caso Collini', enable: true },
      { title: 'Osmosis', enable: true },
      { title: 'American Son', enable: true },
      { title: '13 Reasons Why', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Good Girls', enable: true },
      { title: 'Areia Movediça', enable: true },
      { title: 'The One', enable: true },
      { title: 'Amar', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Se eu não tivesse te conhecido', enable: true },
      { title: 'Segurança em Jogo', enable: true },
      { title: 'Mytho', enable: true },
      { title: 'Diga Quem Sou', enable: true },
      { title: '7 anos', enable: true },
      { title: 'The Sinner - 1ª Temporada', enable: true },
      { title: 'Califado', enable: true },
      { title: 'Olhos Que Condenam', enable: true },
      { title: 'Altos Negócios', enable: true },
      { title: 'Era uma Segunda Vez', enable: true },
    ],
    charts: {
      hex: '#cf1753',
      rgb: '184,25,77',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [12612, 12765, 12900, 13035, 13170, 13302, 13485, 13665, 13845, 13977, 14109, 14241]
      },
      genresChart: {
        titles: ['Drama','Ficção', 'Suspense','Ação','Comédia'],
        values: [28,22,19,17,7]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Crime', 'Investigação','Livro','Psicologico','Europa','Tecnologia','Real','Perseguicao', 'Dramedia'],
        values: [8, 8, 7, 6, 5, 5, 4, 3, 3, 2]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [42, 43]
      },
      interestChart: {
        titles: ['Demais Players', 'Netflix'],
        values: {
          approved: [31, 34],
          notapproved: [6, 7],
          standby: [63, 59]
        }
      }
    }
  },
  telecine: {
    id: 201,
    comercialName: 'Telecine',
    price: 29.9,
    moviePercent: 3,
    seriePercent: 0,
    usersQuantity: '3.807',
    usersReturn: 78, //falta
    recommendationsQuantity: '29.374',
    likedQuantity: '996',
    unlikedQuantity: '213',
    notTodayQuantity: '390',
    notForeverQuantity: 24,
    retentionRate: '100',
    churn: 0,
    mrr: '113.829,30',
    mostRecommendedTitles: [
      { title: 'Entre Facas e Segredos', enable: true },
      { title: 'Corra', enable: true },
      { title: 'A História Pessoal de David Copperfield', enable: true },
      { title: 'Noite Passada em Soho', enable: true },
      { title: 'O Escândalo', enable: true },
      { title: 'Casa Gucci', enable: true },
      { title: 'Magnatas do Crime ', enable: true },
      { title: 'Belfast', enable: true },
      { title: 'Midsommar', enable: true },
      { title: 'Utoya 22 de Julho', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Fique Rico ou Morra Tentando', enable: true },
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'O Escândalo', enable: true },
      { title: 'Midsommar', enable: true },
      { title: 'Hebe - A estrela do Brasil', enable: true },
      { title: 'Entre Facas e Segredos', enable: true },
      { title: 'Corra', enable: true },
      { title: 'Magnatas do Crime', enable: true },
      { title: 'A História Pessoal de David Copperfield', enable: true },
      { title: 'Retrato de uma Jovem em Chamas', enable: true },
    ],
    charts: {
      hex: '#4a70a9',
      rgb: '74,112,169',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2022', 'AGO 2023'],
        values: [2982, 3048, 3117, 3186, 3255, 3324, 3402, 3489, 3576, 3653, 3730, 3807]
      },
      genresChart: {
        titles: ['Suspense', 'Drama', 'Comédia', 'Ação'],
        values: [62, 22, 12, 4]
      },
      subgenresChart: {
        titles: ['Psicologico', 'Sobrenatural', 'Relacoes', 'Oscar', 'Crime', 'Racismo', 'Livro', 'Religião', 'Real', 'Terrorismo'],
        values: [13, 13, 9, 9, 8, 8, 8, 6, 4, 2]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 2]
      },
      interestChart: {
        titles: ['Demais Players', 'Telecine'],
        values: {
          approved: [33, 45],
          notapproved: [6, 10],
          standby: [61, 45]
        }
      }
    }
  },
  apple: {
    id: 59,
    comercialName: 'Apple TV',
    price: 14.9,
    moviePercent: 7,
    seriePercent: 8,
    usersQuantity: '3.657',
    usersReturn: 78, //falta
    recommendationsQuantity: '98.366',
    likedQuantity: '1.053',
    unlikedQuantity: '234',
    notTodayQuantity: '1.131',
    notForeverQuantity: 78,
    retentionRate: '100',
    churn: 0,
    mrr: '54.489,30',
    mostRecommendedTitles: [
      { title: 'Calls', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Em defesa de Jacob', enable: true },
      { title: 'Losing Alice', enable: true },
      { title: 'Três Estranhos Idênticos', enable: true },
      { title: 'Palmer', enable: true },
      { title: 'O Canto do Cisne', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'Sicilian Ghost Story', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Verdade seja dita', enable: true },
      { title: 'For All Mankind', enable: true },
      { title: 'The Morning Show', enable: true },
      { title: 'Servant', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Azul é a cor mais quente', enable: true },
      { title: 'See', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Kursk', enable: true },
      { title: 'WeCrashed', enable: true },
    ],
    charts: {
      hex: '#c4c4c4',
      rgb: '196,196,196',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [2846, 2901, 2969, 3037, 3105, 3171, 3261, 3355, 3450, 3519, 3588, 3657]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Suspense','documentario'],
        values: [67,11,10,5,3]
      },
      subgenresChart: {
        titles: ['Relações','Casal','Investigação','Familia','Independente','Crime','Livro','Cannes','Real','Oscar'],
        values: [13, 11, 7, 7, 6, 6, 6, 6, 5, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [6, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Apple TV'],
        values: {
          approved: [33, 20],
          notapproved: [7, 5],
          standby: [60, 75]
        }
      }
    }
  },
  prime: {
    id: 37,
    comercialName: 'Prime Video',
    price: 14.9,
    moviePercent: 22,
    seriePercent: 9,
    usersQuantity: '10.533',
    usersReturn: 78, //falta
    recommendationsQuantity: '290.389',
    likedQuantity: '5.508',
    unlikedQuantity: '999',
    notTodayQuantity: '840',
    notForeverQuantity: 423,
    retentionRate: '100',
    churn: 0,
    mrr: '156.941,70',
    mostRecommendedTitles: [
      { title: 'DOM', enable: true },
      { title: 'A Grande Mentira', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Capote', enable: true },
      { title: 'O Passado', enable: true },
      { title: 'Ex-Machina', enable: true },
      { title: 'Precisamos falar sobre o Kevin!', enable: true },
      { title: 'Uma razão para viver', enable: true },
      { title: 'Top Model', enable: true },
      { title: 'Sou sua Mulher', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Querida Alice', enable: true },
      { title: 'Creed 2', enable: true },
      { title: 'Respire Fundo', enable: true },
      { title: 'Verdade e Justiça', enable: true },
      { title: 'Blue Valentine', enable: true },
      { title: 'Dentro da Casa', enable: true },
      { title: 'Bata Antes de Entrar', enable: true },
      { title: 'Green Book', enable: true },
      { title: 'Vice', enable: true },
      { title: 'Dor e Glória', enable: true },
    ],
    charts: {
      hex: '#18a0c8',
      rgb: '24,160,200',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [9189, 9333, 9443, 9553, 9663, 9771, 9903, 10048, 10194, 10307, 10420, 10533]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Ficção','Comédia'],
        values: [40,21,14,13,11]
      },
      subgenresChart: {
        titles: ['Relações','Real','Oscar','Livro','Investigação','Crime','Tecnologia','Família','Psicológico','Terrorismo'],
        values: [10,6,6,5,4,4,4,4,3,3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [19, 14]
      },
      interestChart: {
        titles: ['Demais Players', 'Prime Video'],
        values: {
          approved: [33, 31],
          notapproved: [7, 6],
          standby: [60, 63]
        }
      }
    }
  },
  hbo: {
    id: 36,
    comercialName: 'HBO Max',
    price: 34.9,
    moviePercent: 14,
    seriePercent: 15,
    usersQuantity: '5.715',
    usersReturn: 78, //falta
    recommendationsQuantity: '180.139',
    likedQuantity: '2.694',
    unlikedQuantity: '498',
    notTodayQuantity: '807',
    notForeverQuantity: 144,
    retentionRate: '100',
    churn: 0,
    mrr: '199.453,50',
    mostRecommendedTitles: [
      { title: 'Rainhas do Crime', enable: true },
      { title: 'The Undoing', enable: true },
      { title: 'Mare of Easttown', enable: true },
      { title: 'Relatos Selvagens', enable: true },
      { title: '3096 Dias', enable: true },
      { title: 'Caminhos da Memória', enable: true },
      { title: 'Florida Project', enable: true },
      { title: 'O Pintassilgo', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Big Little Lies', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Batalha das Correntes', enable: false },
      { title: 'A Vida Depois ', enable: true },
      { title: 'O Primeiro Milhão', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'The Jinx: The Life and Deaths of Robert Durst', enable: true },
      { title: 'Parasita', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Chernobyl ', enable: true },
      { title: 'Coringa ', enable: true },
      { title: 'Euphoria', enable: true },
    ],
    charts: {
      hex: '#a17dc8',
      rgb: '161,125,200',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2022'],
        values: [4504, 4614, 4717, 4820, 4923, 5025, 5160, 5293, 5427, 5523, 5619, 5715]
      },
      genresChart: {
        titles: ['Drama', 'Ação','Documentário', 'Suspense', 'Comédia'],
        values: [62,16,8,6,5]
      },
      subgenresChart: {
        titles: ['Real', 'Relacoes', 'Crime', 'Investigacao', 'Oscar', 'Familia', 'Livro', 'Violência', 'Político', 'Tecnologia'],
        values: [9, 7, 7, 6, 6, 5, 4, 3, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [12, 11]
      },
      interestChart: {
        titles: ['Demais Players', 'HBO Max'],
        values: {
          approved: [33, 30],
          notapproved: [7, 6],
          standby: [60, 64]
        }
      }
    }
  },
  filmefilme: {
    id: 200,
    comercialName: 'Filme Filme',
    price: 12,
    moviePercent: 2,
    seriePercent: 0,
    usersQuantity: '2.199',
    usersReturn: 78, //falta
    recommendationsQuantity: '20.487',
    likedQuantity: '93',
    unlikedQuantity: '39',
    notTodayQuantity: '126',
    notForeverQuantity: 0,
    retentionRate: '100',
    churn: 0,
    mrr: '26.388,00',
    mostRecommendedTitles: [
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Julho Agosto', enable: true },
      { title: 'A Arte de Amar', enable: true },
      { title: '10.000 KM', enable: true },
      { title: 'Mil Vezes Boa Noite', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'Jovem e Bela', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Café de Flore', enable: true },
      { title: 'Dafne', enable: true },
      { title: '10.000 Km', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: 'Julho Agosto ', enable: false },
      { title: 'Mil Vezes Boa Noite', enable: true },
    ],
    charts: {
      hex: '#dd930d',
      rgb: '221,147,13',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [1589, 1614, 1667, 1720, 1773, 1824, 1878, 1948, 2019, 2079, 2139, 2199]
      },
      genresChart: {
        titles: ['Drama','Suspense', 'Comédia'],
        values: [66,20,14]
      },
      subgenresChart: {
        titles: ['Relacoes','Europa','Casal','Familia','Independente','Crime','Franca','Livro','Romântica','Italia'],
        values: [12, 9, 8, 7, 5, 5, 5, 5, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [1, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Filme Filme'],
        values: {
          approved: [33, 20],
          notapproved: [6, 8],
          standby: [61, 72]
        }
      }
    }
  },
  globoplay: {
    id: 29,
    comercialName: 'Globoplay',
    price: 19.9,
    moviePercent: 4,
    seriePercent: 9,
    usersQuantity: '2.085',
    usersReturn: 69, //falta
    recommendationsQuantity: '67.353',
    likedQuantity: '666',
    unlikedQuantity: '135',
    notTodayQuantity: '627',
    notForeverQuantity: 27,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '41.491,50',
    mostRecommendedTitles: [
      { title: 'O Milagre na Cela 7', enable: true },
      { title: 'Expresso do Amanhã', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'O Labirinto', enable: true },
      { title: 'Iris', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'Shirley', enable: true },
      { title: 'Departure - 1ª Temporada', enable: true },
      { title: 'A Million Little Things', enable: true },
      { title: 'Cidade Proibida', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Por que as Mulheres Matam - 1ª Temporada', enable: true },
      { title: 'Uma Prova de Amor', enable: true },
      { title: 'A Million Little Things', enable: true },
      { title: 'Assédio', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Bastardos Inglórios', enable: true },
      { title: 'O Milagre na Cela 7', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: '11/9 - A Vida sob Ataque', enable: true },
      { title: 'Nudes', enable: true },
    ],
    charts: {
      hex: '#fb1f1f',
      rgb: '251,31,31',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [1038, 1125, 1211, 1297, 1383, 1467, 1575, 1701, 1827, 1913, 1999, 2085]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Documentário', 'Suspense'],
        values: [52,23,12,6,5]
      },
      subgenresChart: {
        titles: ['Dramedia','Livro','Relacoes','Violencia','Politico','Asia','Coreia','Cannes','Oscar','Familia'],
        values: [8, 8, 7, 6, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 5]
      },
      interestChart: {
        titles: ['Demais Players', 'Globoplay'],
        values: {
          approved: [33, 21],
          notapproved: [7, 4],
          standby: [60, 75]
        }
      }
    }
  },
  disney: {
    id: 202,
    comercialName: 'Disney+',
    price: 27.9,
    moviePercent: 5,
    seriePercent: 2,
    usersQuantity: '2.526',
    usersReturn: 69, //falta
    recommendationsQuantity: '31.864',
    likedQuantity: '840',
    unlikedQuantity: '96',
    notTodayQuantity: '147',
    notForeverQuantity: 36,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '70.475,40',
    mostRecommendedTitles: [
      { title: 'A Melhor Ideia', enable: true },
      { title: 'Viúva Negra', enable: true },
      { title: 'Milagre do Rio Hudson', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A História da Pixar', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'Amor, Sublime Amor', enable: true },
      { title: 'Império dos Sonhos', enable: true },
      { title: 'Cruella', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Estrada para a Glória', enable: true },
      { title: 'Homem-Aranha no Aranhaverso', enable: true },
      { title: 'Vingadores - Ultimato', enable: true },
      { title: 'Dumbo', enable: true },
      { title: 'Toy Story 4', enable: true },
      { title: 'O Rei Leão', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'WandaVision', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Viva - A Vida é uma Festa', enable: true },
    ],
    charts: {
      hex: '#64bee7',
      rgb: '100,190,231',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [1248, 1395, 1506, 1617, 1728, 1839, 1962, 2097, 2232, 2330, 2428, 2526]
      },
      genresChart: {
        titles: ['Ação','Animação', 'Documentário', 'Drama'],
        values: [71,23,5,1]
      },
      subgenresChart: {
        titles: ['Marvel', 'Heroi', 'Disney', 'Oscar', 'Aventura', 'Fantasia', 'Perseguicao', 'Nostalgia', 'Pantera', 'Tecnologia'],
        values: [17, 16, 10, 7, 7, 6, 4, 4, 4, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 4]
      },
      interestChart: {
        titles: ['Demais Players', 'Disney+'],
        values: {
          approved: [33, 46],
          notapproved: [6, 5],
          standby: [61, 49]
        }
      }
    }
  },
  star: {
    id: 213,
    comercialName: 'Star+',
    price: 32.9,
    moviePercent: 10,
    seriePercent: 11,
    usersQuantity: '2.229',
    usersReturn: 69, //falta
    recommendationsQuantity: '91.770',
    likedQuantity: '2.424',
    unlikedQuantity: '423',
    notTodayQuantity: '1.077',
    notForeverQuantity: 99,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '73.334,10',
    mostRecommendedTitles: [
      { title: 'A Cabana', enable: true },
      { title: 'Três Anúncios para um Crime', enable: true },
      { title: 'Projeto Gemini', enable: true },
      { title: 'Only Murders in the Building', enable: true },
      { title: 'O segredo dos seus olhos', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'Acredite em Mim: A História de Lisa McVey', enable: true },
      { title: 'Jojo Rabbit', enable: true },
      { title: 'Frankie', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Linha Reta', enable: true },
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Acredite em Mim: A História de Lisa McVey', enable: true },
      { title: 'Bohemian Rhapsody', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'This is Us', enable: true },
      { title: 'A Favorita', enable: true },
      { title: 'Vidro', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Projeto Gemini', enable: true },
    ],
    charts: {
      hex: '#c40f60',
      rgb: '196,15,96',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [1142, 1230, 1323, 1416, 1509, 1602, 1716, 1843, 1971, 2057, 2143, 2229]
      },
      genresChart: {
        titles: ['Ação', 'Drama', 'Comédia', 'Suspense', 'Documentario'],
        values: [47, 41, 6, 4, 2]
      },
      subgenresChart: {
        titles: ['Tecnologia', 'Relacoes','Jovem','Games','Crime','Real','Shyamalan','Herói','Livro','Oscar'],
        values: [10, 8, 8, 8, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [6, 10]
      },
      interestChart: {
        titles: ['Demais Players', 'Star+'],
        values: {
          approved: [32, 41],
          notapproved: [6, 7],
          standby: [62, 52]
        }
      }
    }
  },
  googleplay: {
    id: 153,
    comercialName: 'Google Play',
    price: '-',
    moviePercent: 6,
    seriePercent: 0,
    usersQuantity: '1.068',
    usersReturn: 69, //falta
    recommendationsQuantity: '67.274',
    likedQuantity: '1.389',
    unlikedQuantity: '345',
    notTodayQuantity: '1.440',
    notForeverQuantity: 153,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '-',
    mostRecommendedTitles: [
      { title: 'Buscando...', enable: true },
      { title: 'Roman J. Israel, Esq.', enable: true },
      { title: 'Era uma vez em Hollywood', enable: true },
      { title: 'O Apartamento', enable: true },
      { title: 'Amor, Drogas e Nova York', enable: true },
      { title: 'Blind', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'O Preço da Verdade', enable: true },
      { title: 'Em Pedaços', enable: true },
      { title: 'Steve Jobs', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Roman J. Israel, Esq.', enable: true },
      { title: 'Era uma vez em Hollywood', enable: true },
      { title: 'O Cidadão Ilustre', enable: true },
      { title: '1917', enable: true },
      { title: 'Mercado de Capitais', enable: true },
      { title: 'Yesterday', enable: true },
      { title: 'Amor ou Consequência', enable: true },
      { title: 'Alabama Monroe ', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'O Preço da Verdade ', enable: true },
    ],
    charts: {
      hex: '#07c263',
      rgb: '7,194,99',
      performance: {
        titles: ['SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023', 'JUN 2023', 'JUL 2023', 'AGO 2023'],
        values: [456, 501, 555, 609, 663, 714, 768, 835, 903, 958, 1013, 1068]
      },
      genresChart: {
        titles: ['Drama', 'Comédia', 'Suspense', 'Ação'],
        values: [44, 27, 27, 3]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Violencia', 'Psicologico', 'Casal', 'Aronofsky', 'Religiao', 'Independente', 'Assedio', 'Oscar', 'Familia'],
        values: [13, 10, 9, 8, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 4]
      },
      interestChart: {
        titles: ['Demais Players', 'Google Play'],
        values: {
          approved: [33, 26],
          notapproved: [6, 7],
          standby: [61, 67]
        }
      }
    }
  }
};