import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";

const routes: Routes = [
  {
    path: "dashboard",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "viureview",
        pathMatch: 'full'
      },
      {
        path: "",
        loadChildren:
          "./pages/dashboard/dashboard.module#DashboardModule"
      },
      {
        path: "",
        loadChildren:
          "./pages/dashboard-streaming/dashboard-streaming.module#DashboardStreamingModule"
      },
    ]
  },
  {
    path: "login",
    loadChildren:
      "./pages/login/login.module#LoginModule"
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
