// 05/2023
export const TwentyTwentyThreeMay = {
  viureview: {
    totalUsersQuantity: '249.809', // André
    moviePercent: 70,
    seriePercent: 30,
    usersQuantity: '16.797',
    usersReturn: 73, // André
    recommendationsQuantity: '1.517.804',
    likedQuantity: '38.808',
    unlikedQuantity: '7.647',
    notTodayQuantity: '10.266',
    notForeverQuantity: 1950,
    mostRecommendedTitles: [
      { title: 'Ataque dos Cães (Netflix)', enable: true },
      { title: 'O Caso Collini (Netflix)', enable: true },
      { title: 'Osmosis (Netflix)', enable: true },
      { title: 'Buscando... (Netflix)', enable: true },
      { title: 'Entre Facas e Segredos (Prime Video)', enable: true },
      { title: 'American Son (Netflix)', enable: true },
      { title: '13 Reasons Why (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Areia Movediça (Netflix)', enable: true },
      { title: 'Good Girls (Netflix)', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Linha Reta (Star+)', enable: true },
      { title: 'A Linha Vermelha do Destino (Star+)', enable: true },
      { title: 'Case (Netflix)', enable: true },
      { title: 'Se eu não tivesse te conhecido (Netflix)', enable: true },
      { title: 'Estrada para a Glória (Disney)', enable: true },
      { title: 'Mytho (Netflix)', enable: true },
      { title: 'Segurança em Jogo (Netflix)', enable: true },
      { title: 'Por que as Mulheres Matam - 1ª Temporada (Globoplay)', enable: true },
      { title: 'Acredite em Mim: A História de Lisa McVey (Netflix)', enable: true },
      { title: 'Fique Rico ou Morra Tentando (Netflix)', enable: true },
    ],
    charts: {
      hex: '#F36300',
      rgb: '243,99,0',
      performance: { // André
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022','JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [151198, 156845, 162767, 169752, 175281, 182088, 187931, 196814, 206173, 217630, 233719, 249809]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Ficção','Comédia'],
        values: [34,18,17,16,9]
      },
      subgenresChart: {
        titles: ['Relacoes','Crime','Investigacao','Livro','Psicologico','Real','Europa','Tecnologia','Familia','Oscar'],
        values: [8,7,6,5,5,4,4,4,3,3]
      },
      usersByStreaming: {
        titles: ['NETFLIX', 'PRIME VIDEO', 'HBO Max', 'TELECINE', 'APPLE TV', 'DISNEY+', 'FILME FILME', 'STAR+', 'GLOBOPLAY', 'GOOGLE PLAY'],
        values: [81, 59, 32, 20, 20, 13, 12, 12, 11, 5]
      }
    }
  },
  netflix: {
    id: 27,
    comercialName: 'Netflix',
    price: 39.9,
    moviePercent: 30,
    seriePercent: 47,
    usersQuantity: '13.845',
    usersReturn: 69, //falta
    recommendationsQuantity: '618.557',
    likedQuantity: '23.010',
    unlikedQuantity: '4.626',
    notTodayQuantity: '2.064',
    notForeverQuantity: 849,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '552.415,50',
    mostRecommendedTitles: [
      { title: 'Ataque dos Cães', enable: true },
      { title: 'O Caso Collini', enable: true },
      { title: 'Osmosis', enable: true },
      { title: 'American Son', enable: true },
      { title: '13 Reasons Why', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Areia Movediça', enable: true },
      { title: 'Good Girls', enable: true },
      { title: 'Amar', enable: true },
      { title: 'The One', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Case', enable: true },
      { title: 'Se eu não tivesse te conhecido', enable: true },
      { title: 'Mytho', enable: true },
      { title: 'Segurança em Jogo', enable: true },
      { title: 'Fique Rico ou Morra Tentando', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Diga Quem Sou', enable: true },
      { title: 'Califado', enable: true },
      { title: 'The Sinner - 1ª Temporada', enable: true },
      { title: 'Olhos Que Condenam', enable: true },
    ],
    charts: {
      hex: '#cf1753',
      rgb: '184,25,77',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [11949, 12228, 12507, 12612, 12765, 12900, 13035, 13170, 13302, 13485, 13665, 13845]
      },
      genresChart: {
        titles: ['Drama','Ficção', 'Suspense','Ação','Comédia'],
        values: [27,22,19,17,8]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Crime', 'Investigação','Livro','Psicologico','Europa','Tecnologia','Real','Perseguicao', 'Dramedia'],
        values: [8, 8, 7, 6, 5, 5, 4, 3, 3, 2]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [43, 45]
      },
      interestChart: {
        titles: ['Demais Players', 'Netflix'],
        values: {
          approved: [30, 34],
          notapproved: [6, 7],
          standby: [64, 59]
        }
      }
    }
  },
  telecine: {
    id: 201,
    comercialName: 'Telecine',
    price: 29.9,
    moviePercent: 2,
    seriePercent: 0,
    usersQuantity: '3.576',
    usersReturn: 78, //falta
    recommendationsQuantity: '22.863',
    likedQuantity: '954',
    unlikedQuantity: '204',
    notTodayQuantity: '357',
    notForeverQuantity: 24,
    retentionRate: '100',
    churn: 0,
    mrr: '106.922,40',
    mostRecommendedTitles: [
      { title: 'Entre Facas e Segredos', enable: true },
      { title: 'Corra', enable: true },
      { title: 'Noite Passada em Soho', enable: true },
      { title: 'O Escândalo', enable: true },
      { title: 'Magnatas do Crime ', enable: true },
      { title: 'Belfast', enable: true },
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'Midsommar', enable: true },
      { title: 'A Lenda de Candyman', enable: true },
      { title: 'Retrato de uma Jovem em Chamas', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'O Escândalo', enable: true },
      { title: 'Midsommar', enable: true },
      { title: 'Hebe - A estrela do Brasil', enable: true },
      { title: 'Entre Facas e Segredos', enable: true },
      { title: 'Corra', enable: true },
      { title: 'Magnatas do Crime', enable: true },
      { title: 'Retrato de uma Jovem em Chamas', enable: true },
      { title: 'O Telefone Preto', enable: true },
      { title: 'O Homem do Norte', enable: true },
    ],
    charts: {
      hex: '#4a70a9',
      rgb: '74,112,169',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [2649, 2776, 2904, 2982, 3048, 3117, 3186, 3255, 3324, 3402, 3489, 3576]
      },
      genresChart: {
        titles: ['Suspense', 'Drama', 'Comédia', 'Ação'],
        values: [64, 21, 12, 3]
      },
      subgenresChart: {
        titles: ['Psicologico', 'Sobrenatural', 'Relacoes', 'Oscar', 'Crime', 'Racismo', 'Livro', 'Religião', 'Real', 'Terrorismo'],
        values: [13, 13, 9, 9, 8, 8, 8, 6, 4, 2]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Telecine'],
        values: {
          approved: [32, 46],
          notapproved: [6, 10],
          standby: [62, 44]
        }
      }
    }
  },
  apple: {
    id: 59,
    comercialName: 'Apple TV',
    price: 14.9,
    moviePercent: 7,
    seriePercent: 8,
    usersQuantity: '3.450',
    usersReturn: 78, //falta
    recommendationsQuantity: '97.601',
    likedQuantity: '1.065',
    unlikedQuantity: '228',
    notTodayQuantity: '1149',
    notForeverQuantity: 123,
    retentionRate: '100',
    churn: 0,
    mrr: '51.405,00',
    mostRecommendedTitles: [
      { title: 'Calls', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Em defesa de Jacob', enable: true },
      { title: 'Losing Alice', enable: true },
      { title: 'Três Estranhos Idênticos', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Palmer', enable: true },
      { title: 'O Canto do Cisne', enable: true },
      { title: 'Verdade seja dita', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Verdade seja dita', enable: true },
      { title: 'For All Mankind', enable: true },
      { title: 'The Morning Show', enable: true },
      { title: 'Servant', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Azul é a cor mais quente', enable: true },
      { title: 'See', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Kursk', enable: true },
    ],
    charts: {
      hex: '#c4c4c4',
      rgb: '196,196,196',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [2508, 2638, 2769, 2846, 2901, 2969, 3037, 3105, 3171, 3261, 3355, 3450]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Suspense','Ação'],
        values: [70,10,9,5,3]
      },
      subgenresChart: {
        titles: ['Relações','Casal','Investigação','Familia','Independente','Crime','Livro','Cannes','Real','Oscar'],
        values: [13, 11, 7, 7, 6, 6, 6, 6, 5, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [7, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Apple TV'],
        values: {
          approved: [33, 20],
          notapproved: [7, 4],
          standby: [60, 76]
        }
      }
    }
  },
  prime: {
    id: 37,
    comercialName: 'Prime Video',
    price: 14.9,
    moviePercent: 21,
    seriePercent: 9,
    usersQuantity: '10.194',
    usersReturn: 78, //falta
    recommendationsQuantity: '270.789',
    likedQuantity: '5.313',
    unlikedQuantity: '951',
    notTodayQuantity: '981',
    notForeverQuantity: 372,
    retentionRate: '100',
    churn: 0,
    mrr: '151.890,60',
    mostRecommendedTitles: [
      { title: 'DOM', enable: true },
      { title: 'A Grande Mentira', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Capote', enable: true },
      { title: 'Ex-Machina', enable: true },
      { title: 'O Passado', enable: true },
      { title: 'Precisamos falar sobre o Kevin!', enable: true },
      { title: 'Uma razão para viver', enable: true },
      { title: 'Sou sua Mulher', enable: true },
      { title: 'Top Model', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Querida Alice', enable: true },
      { title: 'Creed 2', enable: true },
      { title: 'Blue Valentine', enable: true },
      { title: 'Green Book', enable: true },
      { title: 'Bata Antes de Entrar', enable: true },
      { title: 'Verdade e Justiça', enable: true },
      { title: 'Vice', enable: true },
      { title: 'Dor e Glória', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'The Boys', enable: true },
    ],
    charts: {
      hex: '#18a0c8',
      rgb: '24,160,200',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [8619, 8872, 9126, 9189, 9333, 9443, 9553, 9663, 9771, 9903, 10048, 10194]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Ficção','Comédia'],
        values: [40,20,14,13,10]
      },
      subgenresChart: {
        titles: ['Relações','Real','Oscar','Livro','Investigação','Crime','Tecnologia','Família','Psicológico','Terrorismo'],
        values: [10,6,6,5,4,4,4,4,3,3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [19, 13]
      },
      interestChart: {
        titles: ['Demais Players', 'Prime Video'],
        values: {
          approved: [33, 31],
          notapproved: [6, 5],
          standby: [61, 64]
        }
      }
    }
  },
  hbo: {
    id: 36,
    comercialName: 'HBO Max',
    price: 34.9,
    moviePercent: 14,
    seriePercent: 13,
    usersQuantity: '5.427',
    usersReturn: 78, //falta
    recommendationsQuantity: '171.291',
    likedQuantity: '2.406',
    unlikedQuantity: '462',
    notTodayQuantity: '882',
    notForeverQuantity: 132,
    retentionRate: '100',
    churn: 0,
    mrr: '189.402,30',
    mostRecommendedTitles: [
      { title: 'Rainhas do Crime', enable: true },
      { title: 'The Undoing', enable: true },
      { title: 'Relatos Selvagens', enable: true },
      { title: 'Mare of Easttown', enable: true },
      { title: '3096 Dias', enable: true },
      { title: 'Caminhos da Memória', enable: true },
      { title: 'Florida Project', enable: true },
      { title: 'O Pintassilgo', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Gravidade', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Batalha das Correntes', enable: false },
      { title: 'A Vida Depois ', enable: true },
      { title: 'The Jinx: The Life and Deaths of Robert Durst', enable: true },
      { title: 'O Primeiro Milhão', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Respire Fundo', enable: true },
      { title: 'Coringa ', enable: true },
      { title: 'Euphoria', enable: true },
      { title: 'Chernobyl ', enable: true },
    ],
    charts: {
      hex: '#a17dc8',
      rgb: '161,125,200',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [3954, 4183, 4413, 4504, 4614, 4717, 4820, 4923, 5025, 5160, 5293, 5427]
      },
      genresChart: {
        titles: ['Drama', 'Ação','Documentário', 'Suspense', 'Comédia'],
        values: [62,16,8,6,4]
      },
      subgenresChart: {
        titles: ['Real', 'Relacoes', 'Crime', 'Investigacao', 'Oscar', 'Familia', 'Livro', 'Violência', 'Político', 'Tecnologia'],
        values: [9, 7, 7, 6, 6, 5, 4, 3, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [12, 11]
      },
      interestChart: {
        titles: ['Demais Players', 'HBO Max'],
        values: {
          approved: [33, 28],
          notapproved: [6, 6],
          standby: [61, 66]
        }
      }
    }
  },
  filmefilme: {
    id: 200,
    comercialName: 'Filme Filme',
    price: 12,
    moviePercent: 2,
    seriePercent: 0,
    usersQuantity: '2.019',
    usersReturn: 78, //falta
    recommendationsQuantity: '19.743',
    likedQuantity: '90',
    unlikedQuantity: '39',
    notTodayQuantity: '117',
    notForeverQuantity: 0,
    retentionRate: '100',
    churn: 0,
    mrr: '24.228,00',
    mostRecommendedTitles: [
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Julho Agosto', enable: true },
      { title: 'A Arte de Amar', enable: true },
      { title: '10.000 KM', enable: true },
      { title: 'Mil Vezes Boa Noite', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Jovem e Bela', enable: true },
      { title: 'Dafne', enable: true },
      { title: 'Café de Flore', enable: true },
      { title: '10.000 Km', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Mil Vezes Boa Noite', enable: true },
    ],
    charts: {
      hex: '#dd930d',
      rgb: '221,147,13',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [1338, 1428, 1518, 1589, 1614, 1667, 1720, 1773, 1824, 1878, 1948, 2019]
      },
      genresChart: {
        titles: ['Drama','Suspense', 'Comédia'],
        values: [67,19,14]
      },
      subgenresChart: {
        titles: ['Relacoes','Europa','Casal','Familia','Independente','Crime','Franca','Livro','Romântica','Italia'],
        values: [12, 9, 8, 7, 5, 5, 5, 5, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [1, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Filme Filme'],
        values: {
          approved: [32, 20],
          notapproved: [6, 10],
          standby: [62, 70]
        }
      }
    }
  },
  globoplay: {
    id: 29,
    comercialName: 'Globoplay',
    price: 19.9,
    moviePercent: 4,
    seriePercent: 10,
    usersQuantity: '1.827',
    usersReturn: 69, //falta
    recommendationsQuantity: '62.761',
    likedQuantity: '606',
    unlikedQuantity: '114',
    notTodayQuantity: '630',
    notForeverQuantity: 21,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '36.357,30',
    mostRecommendedTitles: [
      { title: 'O Milagre na Cela 7', enable: true },
      { title: 'Expresso do Amanhã', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Iris', enable: true },
      { title: 'O Labirinto', enable: true },
      { title: 'Shirley', enable: true },
      { title: 'Departure - 1ª Temporada', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: 'A Million Little Things', enable: true },
      { title: 'Minhas Mães e Meu Pai', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Por que as Mulheres Matam', enable: true },
      { title: 'A Million Little Things', enable: true },
      { title: 'Uma Prova de Amor', enable: true },
      { title: 'Assédio', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: 'O Milagre na Cela 7', enable: true },
      { title: 'Bastardos Inglórios', enable: true },
      { title: '11/9 - A Vida sob Ataque', enable: true },
      { title: 'Nudes', enable: true },
    ],
    charts: {
      hex: '#fb1f1f',
      rgb: '251,31,31',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [576, 768, 960, 1038, 1125, 1211, 1297, 1383, 1467, 1575, 1701, 1827]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Documentário', 'Suspense'],
        values: [49,25,13,7,5]
      },
      subgenresChart: {
        titles: ['Dramedia','Livro','Relacoes','Violencia','Politico','Asia','Coreia','Cannes','Oscar','Familia'],
        values: [8, 8, 7, 6, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 5]
      },
      interestChart: {
        titles: ['Demais Players', 'Globoplay'],
        values: {
          approved: [33, 21],
          notapproved: [6, 4],
          standby: [61, 75]
        }
      }
    }
  },
  disney: {
    id: 202,
    comercialName: 'Disney+',
    price: 27.9,
    moviePercent: 5,
    seriePercent: 2,
    usersQuantity: '2.232',
    usersReturn: 69, //falta
    recommendationsQuantity: '30.757',
    likedQuantity: '801',
    unlikedQuantity: '72',
    notTodayQuantity: '174',
    notForeverQuantity: 33,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '62.272,80',
    mostRecommendedTitles: [
      { title: 'A Melhor Ideia', enable: true },
      { title: 'Viúva Negra', enable: true },
      { title: 'Milagre do Rio Hudson', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A História da Pixar', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'Amor, Sublime Amor', enable: true },
      { title: 'Império dos Sonhos', enable: true },
      { title: 'Cruella', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Estrada para a Glória', enable: true },
      { title: 'Homem-Aranha no Aranhaverso', enable: true },
      { title: 'Vingadores - Ultimato', enable: true },
      { title: 'Dumbo', enable: true },
      { title: 'Toy Story 4', enable: true },
      { title: 'O Rei Leão', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'WandaVision', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Viva - A Vida é uma Festa', enable: true },
    ],
    charts: {
      hex: '#64bee7',
      rgb: '100,190,231',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [717, 952, 1188, 1248, 1395, 1506, 1617, 1728, 1839, 1962, 2097, 2232]
      },
      genresChart: {
        titles: ['Ação','Animação', 'Documentário', 'Drama'],
        values: [69,25,5,1]
      },
      subgenresChart: {
        titles: ['Marvel', 'Heroi', 'Disney', 'Oscar', 'Aventura', 'Fantasia', 'Perseguicao', 'Nostalgia', 'Pantera', 'Tecnologia'],
        values: [17, 16, 10, 7, 7, 6, 4, 4, 4, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 4]
      },
      interestChart: {
        titles: ['Demais Players', 'Disney+'],
        values: {
          approved: [32, 46],
          notapproved: [6, 4],
          standby: [62, 50]
        }
      }
    }
  },
  star: {
    id: 213,
    comercialName: 'Star+',
    price: 32.9,
    moviePercent: 9,
    seriePercent: 10,
    usersQuantity: '1.971',
    usersReturn: 69, //falta
    recommendationsQuantity: '84.775',
    likedQuantity: '2.241',
    unlikedQuantity: '405',
    notTodayQuantity: '1026',
    notForeverQuantity: 87,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '64.845,90',
    mostRecommendedTitles: [
      { title: 'A Cabana', enable: true },
      { title: 'Três Anúncios para um Crime', enable: true },
      { title: 'Projeto Gemini', enable: true },
      { title: 'Only Murders in the Building', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'Acredite em Mim: A História de Lisa McVey', enable: true },
      { title: 'Jojo Rabbit', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Memórias do 11 de Setembro', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Linha Reta', enable: true },
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Acredite em Mim: A História de Lisa McVey', enable: true },
      { title: 'Bohemian Rhapsody', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'This is Us', enable: true },
      { title: 'A Favorita', enable: true },
      { title: 'Vidro', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Projeto Gemini', enable: true },
    ],
    charts: {
      hex: '#c40f60',
      rgb: '196,15,96',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [618, 835, 1053, 1142, 1230, 1323, 1416, 1509, 1602, 1716, 1843, 1971]
      },
      genresChart: {
        titles: ['Ação', 'Drama', 'Comédia', 'Suspense', 'Documentario'],
        values: [49, 39, 5, 5, 2]
      },
      subgenresChart: {
        titles: ['Tecnologia', 'Relacoes','Jovem','Games','Crime','Real','Shyamalan','Herói','Livro','Oscar'],
        values: [10, 8, 8, 8, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [6, 10]
      },
      interestChart: {
        titles: ['Demais Players', 'Star+'],
        values: {
          approved: [32, 41],
          notapproved: [6, 8],
          standby: [62, 51]
        }
      }
    }
  },
  googleplay: {
    id: 153,
    comercialName: 'Google Play',
    price: '-',
    moviePercent: 6,
    seriePercent: 0,
    usersQuantity: '903',
    usersReturn: 69, //falta
    recommendationsQuantity: '68.950',
    likedQuantity: '1.428',
    unlikedQuantity: '363',
    notTodayQuantity: '1.530',
    notForeverQuantity: 144,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '-',
    mostRecommendedTitles: [
      { title: 'Buscando...', enable: true },
      { title: 'Roman J. Israel, Esq.', enable: true },
      { title: 'O Apartamento', enable: true },
      { title: 'Amor, Drogas e Nova York', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'Blind', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'O Preço da Verdade', enable: true },
      { title: 'Em Pedaços', enable: true },
      { title: 'Steve Jobs', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Roman J. Israel, Esq.', enable: true },
      { title: 'Parasita', enable: true },
      { title: 'O Cidadão Ilustre', enable: true },
      { title: '1917', enable: true },
      { title: 'Mercado de Capitais', enable: true },
      { title: 'Yesterday', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'Amor ou Consequência', enable: true },
      { title: 'Alabama Monroe', enable: true },
      { title: 'O Homem Duplicado', enable: true },
    ],
    charts: {
      hex: '#07c263',
      rgb: '7,194,99',
      performance: {
        titles: ['JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023', 'ABR 2023', 'MAI 2023'],
        values: [231, 271, 411, 456, 501, 555, 609, 663, 714, 768, 835, 903]
      },
      genresChart: {
        titles: ['Drama', 'Comédia', 'Suspense', 'Ação'],
        values: [45, 26, 26, 3]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Violencia', 'Psicologico', 'Casal', 'Aronofsky', 'Religiao', 'Independente', 'Assedio', 'Oscar', 'Familia'],
        values: [13, 10, 9, 8, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [5, 4]
      },
      interestChart: {
        titles: ['Demais Players', 'Google Play'],
        values: {
          approved: [33, 26],
          notapproved: [6, 7],
          standby: [62, 67]
        }
      }
    }
  }
};