import { TwentyTwentyThreeMarch } from './months/2023_03';
import { TwentyTwentyThreeFebruary } from './months/2023_02';
import { TwentyTwentyThreeJanuary } from './months/2023_01';
import { TwentyTwentyTwoDecember } from './months/2022_12';
import { TwentyTwentyTwoNovember } from './months/2022_11';
import { TwentyTwentyTwoOctober } from './months/2022_10';
import { TwentyTwentyTwoSeptember } from './months/2022_09';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { TwentyTwentyTwoJanuary } from "./months/2022_01";
import { TwentyTwentyTwoFebruary } from "./months/2022_02";
import { TwentyTwentyTwoMarch } from "./months/2022_03";
import { TwentyTwentyTwoApril } from "./months/2022_04";
import { TwentyTwentyTwoMay } from "./months/2022_05";
import { TwentyTwentyTwoJune } from "./months/2022_06";
import { TwentyTwentyTwoJuly } from "./months/2022_07";
import { TwentyTwentyTwoAugust } from "./months/2022_08";
import { TwentyTwentyThreeApril } from './months/2023_04';
import { TwentyTwentyThreeMay } from './months/2023_05';
import { TwentyTwentyThreeAug } from './months/2023_08';
import { TwentyTwentyThreeJul } from './months/2023_07';
import { TwentyTwentyThreeJun } from './months/2023_06';

export const environment = {
  production: false,
  logins: [
    {
      email: 'contato@viureview.com.br',
      password: 'viu2021@review'
    },
    {
      email: 'globoplay@viewer.com.br',
      password: 'viu2022#globoplay@review'
    },
    {
      email: 'indiquei_pra_ver@viewer.com.br',
      password: 'viu2022#indiquei_pra_ver@review'
    },
    // {
    //   email: 'overcinee@viewer.com.br',
    //   password: 'viu2022#overcinee@review'
    // },
    {
      email: 'dicas_para_maratonar@viewer.com.br',
      password: 'viu2022#dicas_para_maratonar@review'
    },
    {
      email: 'investidor@viewer.com.br',
      password: 'viureview'
    },
    {
      email: 'investidor',
      password: 'viureview'
    },
    // {
    //   email: 'dicas_streaming@viewer.com.br',
    //   password: 'viu2022#dicas_streaming@review'
    // }
  ],
  streamingData: {
    '08-2023' : TwentyTwentyThreeAug,
    '07-2023' : TwentyTwentyThreeJul,
    '06-2023' : TwentyTwentyThreeJun,
    '05-2023' : TwentyTwentyThreeMay,
    '04-2023' : TwentyTwentyThreeApril,
    '03-2023' : TwentyTwentyThreeMarch,
    '02-2023' : TwentyTwentyThreeFebruary,
    '01-2023' : TwentyTwentyThreeJanuary,
    '12-2022' : TwentyTwentyTwoDecember,
    '11-2022' : TwentyTwentyTwoNovember,
    '10-2022' : TwentyTwentyTwoOctober,
    '09-2022' : TwentyTwentyTwoSeptember,
    '08-2022' : TwentyTwentyTwoAugust,
    '07-2022' : TwentyTwentyTwoJuly,
    '06-2022' : TwentyTwentyTwoJune,
    '05-2022' : TwentyTwentyTwoMay,
    '04-2022' : TwentyTwentyTwoApril,
    '03-2022' : TwentyTwentyTwoMarch,
    '02-2022' : TwentyTwentyTwoFebruary,
    '01-2022' : TwentyTwentyTwoJanuary
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
