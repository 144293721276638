// 02/2022
export const TwentyTwentyTwoFebruary = {
  viureview: {
    totalUsersQuantity: '127.837', // André
    moviePercent: 69,
    seriePercent: 31,
    usersQuantity: '12.585',
    usersReturn: 73, // André
    recommendationsQuantity: '1.026.353',
    likedQuantity: '31.011',
    unlikedQuantity: '5.631',
    notTodayQuantity: '4.824',
    notForeverQuantity: 1.842,
    mostRecommendedTitles: [
      { title: 'Buscando... (Netflix)', enable: true },
      { title: 'Ataque dos Cães (Netflix)', enable: true },
      { title: 'Osmosis (Netflix)', enable: true },
      { title: 'O Caso Collini (Netflix)', enable: true },
      { title: 'Entre Facas e Segredos (Prime Video)', enable: true },
      { title: 'American Son (Netflix)', enable: true },
      { title: 'Areia Movediça (Netflix)', enable: true },
      { title: 'A Grande Mentira (Telecine)', enable: true },
      { title: 'Calls (Apple TV)', enable: true },
      { title: 'Amar (Netflix)', enable: true }
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino (Star+)', enable: true },
      { title: 'Case (Netflix)', enable: true },
      { title: 'Mytho (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Se eu não tivesse te conhecido (Netflix)', enable: true },
      { title: 'Creed 2 (Netflix)', enable: true },
      { title: 'Bohemian Rhapsody (Star+)', enable: true },
      { title: 'Bordertown (Netflix)', enable: true },
      { title: 'Diga quem sou (Netflix)', enable: true },
      { title: 'American Son (Netflix)', enable: true },
    ],
    charts: {
      hex: '#F36300',
      rgb: '243,99,0',
      performance: { // André
        titles: ['MAR 21', 'ABR 21', 'MAI 21', 'JUN 21', 'JUL 21', 'AGO 21', 'SET 21', 'OUT 21', 'NOV 21', 'DEZ 21','JAN 22', 'FEV 22'],
        values: [69045, 77227, 83324, 87003, 90106, 92237, 96793, 107273, 112369, 115457, 121559, 127837]
      },
      genresChart: {
        titles: ['Drama','Suspense','Ficção','Ação','Comédia'],
        values: [35,18,17,16,8]
      },
      subgenresChart: {
        titles: ['Relacoes','Crime','Investigacao','Livro','Psicologico','Real','Europa','Tecnologia','Familia','Oscar'],
        values: [7,7,6,6,5,4,4,4,4,3]
      },
      usersByStreaming: {
        titles: ['NETFLIX', 'PRIME VIDEO', 'HBO Max', 'TELECINE', 'APPLE TV', 'FILME FILME', 'GLOBOPLAY', 'DISNEY+', 'STAR+', 'GOOGLE PLAY'],
        values: [86, 61, 27, 18, 17, 9, 1, 1, 1, 1]
      }
    }
  },
  netflix: {
    comercialName: 'Netflix',
    price: 39.9,
    moviePercent: 32,
    seriePercent: 53,
    usersQuantity: '11.247',
    usersReturn: 69, //falta
    recommendationsQuantity: '422.800',
    likedQuantity: '19.758',
    unlikedQuantity: '3.807',
    notTodayQuantity: '1.824',
    notForeverQuantity: 744,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '448.755,30',
    mostRecommendedTitles: [
      { title: 'Buscando...', enable: true },
      { title: 'Ataque dos Cães', enable: true },
      { title: 'Osmosis', enable: true },
      { title: 'O Caso Collini', enable: true },
      { title: 'American Son', enable: true },
      { title: 'Areia Movediça', enable: true },
      { title: 'Amar', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Good Girls', enable: true },
      { title: 'The One', enable: true }
    ],
    mostInterestedTitles: [
      { title: 'Case', enable: true },
      { title: 'Mytho', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Se eu não tivesse te conhecido', enable: true },
      { title: 'Creed 2', enable: false },
      { title: 'Bordertown', enable: true },
      { title: 'Diga quem sou', enable: true },
      { title: 'American Son', enable: true },
      { title: 'Era uma Segunda Vez', enable: true },
      { title: 'Roman J. Israel, Esq.', enable: true },
    ],
    charts: {
      hex: '#cf1753',
      rgb: '184,25,77',
      performance: {
        titles: ['MAR 2021','ABR 2021', 'MAI 2021', 'JUN 2021', 'JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021','NOV 2021','DEZ 2021', 'JAN 2022', 'FEV 2022'],
        values: [9183, 10080, 10290, 10431, 10443, 10575, 10611, 10734, 10854, 10992, 11058, 11247]
      },
      genresChart: {
        titles: ['Drama','Ficção', 'Suspense','Ação','Comédia'],
        values: [28,24,19,14,9]
      },
      subgenresChart: {
        titles: ['Investigação','Crime','Relacoes','Psicologico','Europa','Livro','Tecnologia','Real','Perseguicao', 'Espanha'],
        values: [8, 8, 6, 6, 6, 6, 5, 3, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [43, 50]
      },
      interestChart: {
        titles: ['Demais Players', 'Netflix'],
        values: {
          approved: [34, 36],
          notapproved: [5, 7],
          standby: [61, 57]
        }
      }
    }
  },
  telecine: {
    comercialName: 'Telecine',
    price: 37.9,
    moviePercent: 5,
    seriePercent: 0,
    usersQuantity: '2.445',
    usersReturn: 78, //falta
    recommendationsQuantity: '37.300',
    likedQuantity: '1.068',
    unlikedQuantity: '225',
    notTodayQuantity: '216',
    notForeverQuantity: 144,
    retentionRate: '100',
    churn: 0,
    mrr: '92.665,50',
    mostRecommendedTitles: [
      { title: 'A Grande Mentira', enable: true },
      { title: 'Silêncio', enable: false },
      { title: 'O Caso Richard Jewell', enable: true },
      { title: 'Relatos Selvagens', enable: true },
      { title: 'Extraordinário', enable: true },
      { title: 'Em Defesa de Cristo', enable: true },
      { title: 'Projeto Gemini', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'A Jornada', enable: true },
      { title: 'The Square', enable: false },
    ],
    mostInterestedTitles: [
      { title: 'Parasita', enable: true },
      { title: 'Coringa', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'IT - Capítulo II', enable: true },
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'Projeto Gemini', enable: true },
      { title: 'O Caso Richard Jewell', enable: true },
      { title: 'A Grande Mentira', enable: true },
      { title: 'Divaldo - O Mensageiro da Paz', enable: false },
      { title: 'The Square', enable: false },
    ],
    charts: {
      hex: '#4a70a9',
      rgb: '74,112,169',
      performance: {
        titles: ['MAR 2021','ABR 2021', 'MAI 2021', 'JUN 2021', 'JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022'],
        values: [1704, 1962, 2034, 2109, 2118, 2148, 2169, 2211, 2271, 2334, 2364, 2445]
      },
      genresChart: {
        titles: ['Drama', 'Suspense','Ação', 'Comédia'],
        values: [55,27,11,8]
      },
      subgenresChart: {
        titles: ['Relações','Livro','Real','Família','Dramédia','Jovem','Stephen King','Sobrenatural','Oscar','Crime'],
        values: [8, 7, 6, 6, 4, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 5]
      },
      interestChart: {
        titles: ['Demais Players', 'Telecine'],
        values: {
          approved: [35, 33],
          notapproved: [6, 7],
          standby: [59, 60]
        }
      }
    }
  },
  apple: {
    comercialName: 'Apple TV',
    price: 9.9,
    moviePercent: 8,
    seriePercent: 7,
    usersQuantity: '2.235',
    usersReturn: 78, //falta
    recommendationsQuantity: '77.590',
    likedQuantity: '1128',
    unlikedQuantity: '168',
    notTodayQuantity: '462',
    notForeverQuantity: 138,
    retentionRate: '100',
    churn: 0,
    mrr: '22.126,50',
    mostRecommendedTitles: [
      { title: 'Calls', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Cabana', enable: true },
      { title: 'Em defesa de Jacob', enable: true },
      { title: 'Losing Alice', enable: true },
      { title: 'Corra', enable: true },
      { title: 'Três Estranhos Idênticos', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'Sicilian Ghost Story', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Morning Show', enable: true },
      { title: 'For All Mankind', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Servant', enable: true },
      { title: 'See', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Azul é a cor mais quente', enable: true },
      { title: 'Kursk', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'Synonymes', enable: true },
    ],
    charts: {
      hex: '#c4c4c4',
      rgb: '196,196,196',
      performance: {
        titles: ['MAR 2021','ABR 2021', 'MAI 2021', 'JUN 2021', 'JUL 2021', 'AGO 2021','SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022'],
        values: [1617, 1785, 1839, 1890, 1896, 1926, 1944, 1986, 2022, 2109, 2148, 2235]
      },
      genresChart: {
        titles: ['Drama','Suspense','Comédia','Ficção','Ação'],
        values: [59,29,4,4,2]
      },
      subgenresChart: {
        titles: ['Relações','Casal','Oscar','Racismo','Psicológico','Livro','Real','Sobrenatural','Crime','Investigação'],
        values: [15, 7, 7, 6, 6, 6, 6, 5, 5, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [8, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Apple TV'],
        values: {
          approved: [35, 30],
          notapproved: [6, 4],
          standby: [59, 66]
        }
      }
    }
  },
  prime: {
    comercialName: 'Prime Video',
    price: 9.9,
    moviePercent: 20,
    seriePercent: 11,
    usersQuantity: '8.019',
    usersReturn: 78, //falta
    recommendationsQuantity: '177.949',
    likedQuantity: '4.857',
    unlikedQuantity: '849',
    notTodayQuantity: '561',
    notForeverQuantity: 312,
    retentionRate: '100',
    churn: 0,
    mrr: '79.388,10',
    mostRecommendedTitles: [
      { title: 'Entre Facas e Segredos', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'DOM', enable: true },
      { title: 'O Passado', enable: true },
      { title: 'Precisamos falar sobre o Kevin!', enable: true },
      { title: 'Capote', enable: true },
      { title: 'Uma Razão Para Viver ', enable: true },
      { title: 'A Grande Jogada', enable: true },
      { title: 'Top Model', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Green Book', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Blue Valentine', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'The Boys', enable: true },
      { title: 'Bata antes de Entrar', enable: true },
      { title: 'Dor e Glória', enable: true },
      { title: 'Era uma vez em Hollywood', enable: true },
      { title: 'Transparent', enable: true },
      { title: 'Anna', enable: true },
    ],
    charts: {
      hex: '#18a0c8',
      rgb: '24,160,200',
      performance: {
        titles: ['MAR 2021','ABR 2021', 'MAI 2021', 'JUN 2021', 'JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022'],
        values: [6231, 6996, 7191, 7314, 7323, 7434, 7461, 7557, 7674, 7815, 7869, 8019]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Comédia','Ficção'],
        values: [43,27,18,6,6]
      },
      subgenresChart: {
        titles: ['Relações','Real','Livro','Família','Psicológico','Religião','Investigação','Crime','Oscar','Tecnologia'],
        values: [8,6,5,5,4,4,4,4,4,4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [18, 12]
      },
      interestChart: {
        titles: ['Demais Players', 'Prime Video'],
        values: {
          approved: [35, 35],
          notapproved: [6, 6],
          standby: [59, 59]
        }
      }
    }
  },
  hbo: {
    comercialName: 'HBO Max',
    price: 27.9,
    moviePercent: 11,
    seriePercent: 14,
    usersQuantity: '3.480',
    usersReturn: 78, //falta
    recommendationsQuantity: '106.192',
    likedQuantity: '1.629',
    unlikedQuantity: '261',
    notTodayQuantity: '477',
    notForeverQuantity: 132,
    retentionRate: '100',
    churn: 0,
    mrr: '97.092,00',
    mostRecommendedTitles: [
      { title: 'The Undoing', enable: true },
      { title: 'Rainhas do Crime', enable: true },
      { title: 'Mare of Easttown', enable: true },
      { title: '3096 Dias', enable: true },
      { title: 'Caminhos da Memória', enable: true },
      { title: 'Years and Years', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'Gravidade', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Jinx: The Life and Deaths of Robert Durst', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Euphoria', enable: true },
      { title: 'Nasce uma Estrela', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'Years and Years', enable: true },
      { title: 'Chernobyl ', enable: true },
      { title: 'Mrs. Fletcher', enable: true },
      { title: 'The Night Of', enable: false },
      { title: 'Succession', enable: true },
    ],
    charts: {
      hex: '#a17dc8',
      rgb: '161,125,200',
      performance: {
        titles: ['MAR 2021','ABR 2021', 'MAI 2021', 'JUN 2021','JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022','FEV 2022'],
        values: [2394, 2673, 2757, 2847, 2853, 2946, 2985, 3060, 3153, 3276, 3333, 3480]
      },
      genresChart: {
        titles: ['Drama', 'Suspense','Ação','Documentário', 'Comédia'],
        values: [51,17,14,9,4]
      },
      subgenresChart: {
        titles: ['Livro','Real','Relações','Investigação','Crime','Politico','Religião','Sobrenatural','Psicológico','Família'],
        values: [10, 8, 7, 6, 5, 4, 4, 4, 4, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [11, 11]
      },
      interestChart: {
        titles: ['Demais Players', 'HBO Max'],
        values: {
          approved: [35, 32],
          notapproved: [6, 5],
          standby: [59, 63]
        }
      }
    }
  },
  filmefilme: {
    comercialName: 'Filme Filme',
    price: 10,
    moviePercent: 2,
    seriePercent: 0,
    usersQuantity: '1.191',
    usersReturn: 78, //falta
    recommendationsQuantity: '17.611',
    likedQuantity: '78',
    unlikedQuantity: '18',
    notTodayQuantity: '42',
    notForeverQuantity: 0,
    retentionRate: '100',
    churn: 0,
    mrr: '11.910,00',
    mostRecommendedTitles: [
      { title: 'O que os homens falam', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Dentro da Casa', enable: true },
      { title: 'Um Reencontro', enable: true },
      { title: 'Acima das Nuvens', enable: false },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'Tudo pelo Poder', enable: false },
      { title: 'A Casa que Jack Construiu', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'The China Hustle', enable: false },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Acima das Nuvens', enable: false },
      { title: 'O que os homens falam', enable: true },
      { title: 'Tudo pelo Poder', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Por que você partiu?', enable: false },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
    ],
    charts: {
      hex: '#dd930d',
      rgb: '221,147,13',
      performance: {
        titles: ['MAR 2021','ABR 2021','MAI 2021','JUN 2021','JUL 2021', 'AGO 2021','SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022','FEV 2022'],
        values: [885, 969, 999, 1023, 1029, 1041, 1047, 1062, 1077, 1113, 1146, 1191]
      },
      genresChart: {
        titles: ['Drama','Comédia', 'Documentário', 'Suspense'],
        values: [74,14,11,2]
      },
      subgenresChart: {
        titles: ['Relações','Casal','Dramédia','Independente','Europa','Família','Investigação','Crime','Itália','Psicológico'],
        values: [11, 7, 6, 6, 6, 6, 5, 5, 5, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Filme Filme'],
        values: {
          approved: [35, 30],
          notapproved: [6, 5],
          standby: [59, 65]
        }
      }
    }
  },
  globoplay: {
    comercialName: 'Globoplay',
    price: 22.9,
    moviePercent: 6,
    seriePercent: 8,
    usersQuantity: '162',
    usersReturn: 69, //falta
    recommendationsQuantity: '55.063',
    likedQuantity: '612',
    unlikedQuantity: '99',
    notTodayQuantity: '441',
    notForeverQuantity: 33,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '3.709,80',
    mostRecommendedTitles: [
      { title: 'O Milagre na Cela 7 ', enable: true },
      { title: 'A Very English Scandal', enable: false },
      { title: 'Flesh and Blood: Um Crime Na Vizinhança', enable: true },
      { title: 'Em Prantos', enable: false },
      { title: 'Expresso do Amanhã', enable: true },
      { title: 'O Labirinto', enable: true },
      { title: 'Iris', enable: true },
      { title: 'Julieta', enable: true },
      { title: 'Me chame pelo seu nome', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Million Little Things', enable: true },
      { title: 'Assédio', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Em Prantos', enable: false },
      { title: 'Me chame pelo seu nome', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: 'Dunkirk', enable: true },
      { title: 'Expresso do Amanhã ', enable: true },
      { title: 'Amorteamo', enable: true },
      { title: '11/9 - A Vida sob Ataque', enable: true },
    ],
    charts: {
      hex: '#fb1f1f',
      rgb: '251,31,31',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022'],
        values: [0, 45, 162]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Documentário', 'Ação'],
        values: [64,18,12,4,1]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Oscar', 'Livro', 'Lgbt', 'Familia', 'Dramedia', 'Europa', 'Real', 'Independente', 'Itália'],
        values: [9, 8, 6, 6, 6, 5, 5, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [6, 5]
      },
      interestChart: {
        titles: ['Demais Players', 'Globoplay'],
        values: {
          approved: [35, 34],
          notapproved: [6, 6],
          standby: [59, 60]
        }
      }
    }
  },
  disney: {
    comercialName: 'Disney+',
    price: 27.9,
    moviePercent: 5,
    seriePercent: 3,
    usersQuantity: '201',
    usersReturn: 69, //falta
    recommendationsQuantity: '21.217',
    likedQuantity: '321',
    unlikedQuantity: '24',
    notTodayQuantity: '93',
    notForeverQuantity: 30,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '5.607,90',
    mostRecommendedTitles: [
      { title: 'A Melhor Ideia', enable: true },
      { title: 'Viúva Negra', enable: true },
      { title: 'Milagre do Rio Hudson', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A História da Pixar', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'Império dos Sonhos', enable: true },
      { title: 'Cruella ', enable: true },
      { title: 'The Mandalorian', enable: true }
    ],
    mostInterestedTitles: [
      { title: 'Vingadores - Ultimato', enable: true },
      { title: 'Dumbo', enable: true },
      { title: 'Toy Story 4', enable: true },
      { title: 'O Rei Leão', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'WandaVision', enable: true },
      { title: 'Viva - A Vida é uma Festa', enable: true },
      { title: 'The Mandalorian', enable: true },
      { title: 'A História do Imagineering', enable: true },
    ],
    charts: {
      hex: '#64bee7',
      rgb: '100,190,231',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022'],
        values: [0, 63, 201]
      },
      genresChart: {
        titles: ['Ação','Animação', 'Documentário', 'Drama'],
        values: [44,43,11,3]
      },
      subgenresChart: {
        titles: ['Disney', 'Fantasia', 'Aventura', 'Heroi', 'Marvel', 'Oscar', 'Nostalgia', 'Tecnologia', 'Real', 'Empreendedorismo'],
        values: [16, 12, 12, 10, 10, 8, 5, 4, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 3]
      },
      interestChart: {
        titles: ['Demais Players', 'Disney+'],
        values: {
          approved: [35, 60],
          notapproved: [6, 4],
          standby: [59, 36]
        }
      }
    }
  },
  star: {
    comercialName: 'Star+',
    price: 32.9,
    moviePercent: 5,
    seriePercent: 4,
    usersQuantity: '183',
    usersReturn: 69, //falta
    recommendationsQuantity: '33.974',
    likedQuantity: '813',
    unlikedQuantity: '81',
    notTodayQuantity: '396',
    notForeverQuantity: 27,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '6.020,70',
    mostRecommendedTitles: [
      { title: 'Três Anúncios para um Crime', enable: true },
      { title: 'Her', enable: true },
      { title: 'Jojo Rabbit', enable: true },
      { title: 'Only Murders in the Building', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Memórias do 11 de Setembro', enable: true },
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Jogo do Dinheiro', enable: true },
      { title: 'A Favorita', enable: true },
      { title: 'Questão de Tempo', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Bohemian Rhapsody', enable: true },
      { title: 'Her', enable: true },
      { title: 'This is Us', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'A Favorita', enable: true },
      { title: 'Vidro', enable: true },
      { title: 'Ford vs Ferrari', enable: true },
      { title: 'Mercado de Capitais', enable: true },
      { title: 'Judy', enable: true },
    ],
    charts: {
      hex: '#c40f60',
      rgb: '196,15,96',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022'],
        values: [0, 45, 183]
      },
      genresChart: {
        titles: ['Drama', 'Ação', 'Ficção', 'Comédia', 'Documentário'],
        values: [54,23,11,9,2]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Oscar', 'Familia', 'Shyamalan', 'Heroi', 'Marvel', 'Casal', 'Real', 'Tecnologia', 'Música'],
        values: [13, 9, 8, 6, 6, 6, 5, 4, 4, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [3, 5]
      },
      interestChart: {
        titles: ['Demais Players', 'Star+'],
        values: {
          approved: [35, 45],
          notapproved: [6, 5],
          standby: [59, 50]
        }
      }
    }
  },
  googleplay: {
    comercialName: 'Google Play',
    price: '-',
    moviePercent: 3,
    seriePercent: 1,
    usersQuantity: '66',
    usersReturn: 69, //falta
    recommendationsQuantity: '24.493',
    likedQuantity: '300',
    unlikedQuantity: '51',
    notTodayQuantity: '192',
    notForeverQuantity: 111,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '-',
    mostRecommendedTitles: [
      { title: 'O Apartamento', enable: true },
      { title: 'Luta por Justiça', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'Amor, Drogas e Nova York', enable: true },
      { title: 'Noite Passada em Soho', enable: true },
      { title: 'Corpo e Alma', enable: true },
      { title: 'Happy End', enable: true },
      { title: 'Kiki', enable: true },
      { title: 'Os Meninos que enganavam Nazistas', enable: true },
      { title: 'Amor ou Consequência', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Amor ou Consequência', enable: true },
      { title: 'Luta por Justiça', enable: true },
      { title: 'A Luz entre Oceanos', enable: true },
      { title: 'Kiki', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'O Apartamento', enable: true },
      { title: 'Whitney: Can I be me', enable: false },
      { title: 'Happy End', enable: true },
      { title: 'Coach Carter', enable: true },
      { title: 'Os Meninos que enganavam Nazistas', enable: true },
    ],
    charts: {
      hex: '#07c263',
      rgb: '7,194,99',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022'],
        values: [0, 21, 66]
      },
      genresChart: {
        titles: ['Drama', 'Comédia', 'Documentário', 'Suspense'],
        values: [85,12,2,1]
      },
      subgenresChart: {
        titles: ['Relacoes','Independente','Casal','Real','Cannes','Oscar','Investigacao','Crime','Livro','Familia'],
        values: [13, 9, 8, 6, 6, 6, 5, 5, 5, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 2]
      },
      interestChart: {
        titles: ['Demais Players', 'Google Play'],
        values: {
          approved: [35, 21],
          notapproved: [6, 4],
          standby: [59, 75]
        }
      }
    }
  },
};