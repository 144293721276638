// 03/2022
export const TwentyTwentyTwoMarch = {
  viureview: {
    totalUsersQuantity: '134.682', // André
    moviePercent: 69,
    seriePercent: 31,
    usersQuantity: '12.693',
    usersReturn: 73, // André
    recommendationsQuantity: '1.082.902',
    likedQuantity: '32.058',
    unlikedQuantity: '5.859',
    notTodayQuantity: '5.856',
    notForeverQuantity: 1.848,
    mostRecommendedTitles: [
      { title: 'O Caso Collini (Netflix)', enable: true },
      { title: 'Buscando... (Netflix)', enable: true },
      { title: 'Ataque dos Cães (Netflix)', enable: true },
      { title: 'Osmosis (Netflix)', enable: true },
      { title: 'Entre Facas e Segredos (Prime Video)', enable: true },
      { title: 'American Son (Netflix)', enable: true },
      { title: 'Areia Movediça (Netflix)', enable: true },
      { title: 'A Grande Mentira (Telecine)', enable: true },
      { title: 'Calls (Apple TV)', enable: true },
      { title: 'Amar (Netflix)', enable: true }
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino (Star+)', enable: true },
      { title: 'Case (Netflix)', enable: true },
      { title: 'Mytho (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Se eu não tivesse te conhecido (Netflix)', enable: true },
      { title: 'Creed 2 (Netflix)', enable: true },
      { title: 'Bohemian Rhapsody (Star+)', enable: true },
      { title: 'Bordertown (Netflix)', enable: true },
      { title: 'Diga quem sou (Netflix)', enable: true },
      { title: 'Roman J. Israel, Esq. (Netflix)', enable: true },
    ],
    charts: {
      hex: '#F36300',
      rgb: '243,99,0',
      performance: { // André
        titles: ['ABR 21', 'MAI 21', 'JUN 21', 'JUL 21', 'AGO 21', 'SET 21', 'OUT 21', 'NOV 21', 'DEZ 21','JAN 22', 'FEV 22', 'MAR 2022'],
        values: [77227, 83324, 87003, 90106, 92237, 96793, 107273, 112369, 115457, 121559, 127837, 134682]
      },
      genresChart: {
        titles: ['Drama','Suspense','Ficção','Ação','Comédia'],
        values: [35,18,17,16,8]
      },
      subgenresChart: {
        titles: ['Relacoes','Crime','Investigacao','Livro','Psicologico','Real','Europa','Tecnologia','Familia','Oscar'],
        values: [7,7,6,6,5,4,4,4,3,3]
      },
      usersByStreaming: {
        titles: ['NETFLIX', 'PRIME VIDEO', 'HBO Max', 'TELECINE', 'APPLE TV', 'FILME FILME', 'GLOBOPLAY', 'DISNEY+', 'STAR+', 'GOOGLE PLAY'],
        values: [86, 61, 27, 18, 17, 9, 2, 2, 2, 1]
      }
    }
  },
  netflix: {
    id: 27,
    comercialName: 'Netflix',
    price: 39.9,
    moviePercent: 32,
    seriePercent: 52,
    usersQuantity: '11.349',
    usersReturn: 69, //falta
    recommendationsQuantity: '437.451',
    likedQuantity: '20.208',
    unlikedQuantity: '3.960',
    notTodayQuantity: '2.013',
    notForeverQuantity: 756,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '452.825,10',
    mostRecommendedTitles: [
      { title: 'O Caso Collini', enable: true },
      { title: 'Buscando...', enable: true },
      { title: 'Ataque dos Cães', enable: true },
      { title: 'Osmosis', enable: true },
      { title: 'American Son', enable: true },
      { title: 'Areia Movediça', enable: true },
      { title: 'Amar', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Good Girls', enable: true },
      { title: 'The One', enable: true }
    ],
    mostInterestedTitles: [
      { title: 'Case', enable: true },
      { title: 'Mytho', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Se eu não tivesse te conhecido', enable: true },
      { title: 'Bordertown', enable: true },
      { title: 'Diga quem sou', enable: true },
      { title: 'Roman J. Israel, Esq.', enable: true },
      { title: 'Era uma Segunda Vez', enable: true },
      { title: 'American Son', enable: true },
      { title: 'As Casas mais Extraordinárias do Mundo', enable: true },
    ],
    charts: {
      hex: '#cf1753',
      rgb: '184,25,77',
      performance: {
        titles: ['ABR 2021', 'MAI 2021', 'JUN 2021', 'JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021','NOV 2021','DEZ 2021', 'JAN 2022', 'FEV 2022', 'MAR 2022'],
        values: [10080, 10290, 10431, 10443, 10575, 10611, 10734, 10854, 10992, 11058, 11247, 11349]
      },
      genresChart: {
        titles: ['Drama','Ficção', 'Suspense','Ação','Comédia'],
        values: [28,24,18,15,9]
      },
      subgenresChart: {
        titles: ['Crime', 'Investigação','Relacoes','Psicologico','Europa','Livro','Tecnologia','Real','Perseguicao', 'Espanha'],
        values: [9, 8, 6, 6, 6, 6, 5, 3, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [42, 49]
      },
      interestChart: {
        titles: ['Demais Players', 'Netflix'],
        values: {
          approved: [34, 36],
          notapproved: [5, 7],
          standby: [61, 57]
        }
      }
    }
  },
  telecine: {
    id: 201,
    comercialName: 'Telecine',
    price: 37.9,
    moviePercent: 4,
    seriePercent: 0,
    usersQuantity: '2.490',
    usersReturn: 78, //falta
    recommendationsQuantity: '28.169',
    likedQuantity: '975',
    unlikedQuantity: '195',
    notTodayQuantity: '219',
    notForeverQuantity: 126,
    retentionRate: '100',
    churn: 0,
    mrr: '94.371,00',
    mostRecommendedTitles: [
      { title: 'A Grande Mentira', enable: true },
      { title: 'Silêncio', enable: false },
      { title: 'O Caso Richard Jewell', enable: true },
      { title: 'Extraordinário', enable: true },
      { title: 'Em Defesa de Cristo', enable: true },
      { title: 'A Jornada', enable: true },
      { title: 'The Square', enable: false },
      { title: 'É apenas o Fim do Mundo', enable: true },
      { title: 'Parasita', enable: true },
      { title: 'O melhor está por vir', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Parasita', enable: true },
      { title: 'Coringa', enable: true },
      { title: 'IT - Capítulo II', enable: true },
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'O Caso Richard Jewell', enable: true },
      { title: 'Aves de Rapina', enable: true },
      { title: 'A Grande Mentira', enable: true },
      { title: 'Divaldo - O Mensageiro da Paz', enable: false },
      { title: 'The Square', enable: false },
      { title: 'Hebe - A estrela do Brasil', enable: true },
    ],
    charts: {
      hex: '#4a70a9',
      rgb: '74,112,169',
      performance: {
        titles: ['ABR 2021', 'MAI 2021', 'JUN 2021', 'JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022'],
        values: [1962, 2034, 2109, 2118, 2148, 2169, 2211, 2271, 2334, 2364, 2445, 2490]
      },
      genresChart: {
        titles: ['Drama', 'Suspense','Ação', 'Comédia'],
        values: [53,29,10,8]
      },
      subgenresChart: {
        titles: ['Relações','Livro','Jovem','Família','Psicologico','Real','Dramedia','Heroi','DC','Stephen King'],
        values: [9, 9, 7, 7, 4, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [3, 4]
      },
      interestChart: {
        titles: ['Demais Players', 'Telecine'],
        values: {
          approved: [35, 34],
          notapproved: [6, 7],
          standby: [59, 59]
        }
      }
    }
  },
  apple: {
    id: 59,
    comercialName: 'Apple TV',
    price: 9.9,
    moviePercent: 8,
    seriePercent: 7,
    usersQuantity: '2.286',
    usersReturn: 78, //falta
    recommendationsQuantity: '79.873',
    likedQuantity: '1152',
    unlikedQuantity: '165',
    notTodayQuantity: '513',
    notForeverQuantity: 129,
    retentionRate: '100',
    churn: 0,
    mrr: '22.631,40',
    mostRecommendedTitles: [
      { title: 'Calls', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Em defesa de Jacob', enable: true },
      { title: 'Corra', enable: true },
      { title: 'Losing Alice', enable: true },
      { title: 'Três Estranhos Idênticos', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'Sicilian Ghost Story', enable: true },
      { title: 'O Canto do Cisne', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Morning Show', enable: true },
      { title: 'For All Mankind', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Servant', enable: true },
      { title: 'See', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Azul é a cor mais quente', enable: true },
      { title: 'Kursk', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'Synonymes', enable: true },
    ],
    charts: {
      hex: '#c4c4c4',
      rgb: '196,196,196',
      performance: {
        titles: ['ABR 2021', 'MAI 2021', 'JUN 2021', 'JUL 2021', 'AGO 2021','SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022'],
        values: [1785, 1839, 1890, 1896, 1926, 1944, 1986, 2022, 2109, 2148, 2235, 2286]
      },
      genresChart: {
        titles: ['Drama','Suspense','Comédia','Ficção','Ação'],
        values: [57,30,5,4,3]
      },
      subgenresChart: {
        titles: ['Relações','Crime','Oscar','Casal','Psicológico','Racismo','Real','Sobrenatural','Livro','Investigação'],
        values: [14, 9, 7, 7, 6, 6, 5, 5, 5, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [8, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Apple TV'],
        values: {
          approved: [35, 30],
          notapproved: [6, 4],
          standby: [59, 66]
        }
      }
    }
  },
  prime: {
    id: 37,
    comercialName: 'Prime Video',
    price: 9.9,
    moviePercent: 20,
    seriePercent: 11,
    usersQuantity: '8.103',
    usersReturn: 78, //falta
    recommendationsQuantity: '186.071',
    likedQuantity: '5.010',
    unlikedQuantity: '876',
    notTodayQuantity: '750',
    notForeverQuantity: 330,
    retentionRate: '100',
    churn: 0,
    mrr: '80.219,70',
    mostRecommendedTitles: [
      { title: 'Entre Facas e Segredos', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'DOM', enable: true },
      { title: 'O Passado', enable: true },
      { title: 'Precisamos falar sobre o Kevin!', enable: true },
      { title: 'Capote', enable: true },
      { title: 'Uma Razão Para Viver ', enable: true },
      { title: 'A Grande Jogada', enable: true },
      { title: 'Top Model', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Creed 2', enable: true },
      { title: 'Green Book', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Blue Valentine', enable: true },
      { title: 'The Boys', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Bata antes de Entrar', enable: true },
      { title: 'Era uma vez em Hollywood', enable: true },
      { title: 'Dor e Glória', enable: true },
      { title: 'Transparent', enable: true },
    ],
    charts: {
      hex: '#18a0c8',
      rgb: '24,160,200',
      performance: {
        titles: ['ABR 2021', 'MAI 2021', 'JUN 2021', 'JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022'],
        values: [6996, 7191, 7314, 7323, 7434, 7461, 7557, 7674, 7815, 7869, 8019, 8103]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Comédia','Ficção'],
        values: [41,29,19,7,4]
      },
      subgenresChart: {
        titles: ['Relações','Real','Psicológico','Tecnologia','Religião','Investigação','Crime','Livro','Oscar','Família'],
        values: [8,6,5,5,4,4,4,4,4,4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [18, 13]
      },
      interestChart: {
        titles: ['Demais Players', 'Prime Video'],
        values: {
          approved: [35, 34],
          notapproved: [6, 6],
          standby: [59, 60]
        }
      }
    }
  },
  hbo: {
    id: 36,
    comercialName: 'HBO Max',
    price: 27.9,
    moviePercent: 11,
    seriePercent: 13,
    usersQuantity: '3.564',
    usersReturn: 78, //falta
    recommendationsQuantity: '112.851',
    likedQuantity: '1.635',
    unlikedQuantity: '258',
    notTodayQuantity: '666',
    notForeverQuantity: 102,
    retentionRate: '100',
    churn: 0,
    mrr: '99.435,60',
    mostRecommendedTitles: [
      { title: 'The Undoing', enable: true },
      { title: 'Rainhas do Crime', enable: true },
      { title: 'Mare of Easttown', enable: true },
      { title: 'Relatos Selvagens', enable: true },
      { title: '3096 Dias', enable: true },
      { title: 'Caminhos da Memória', enable: true },
      { title: 'Years and Years', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'Big Little Lies', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Jinx: The Life and Deaths of Robert Durst', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Euphoria', enable: true },
      { title: 'Nasce uma Estrela', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'Years and Years', enable: true },
      { title: 'Chernobyl ', enable: true },
      { title: 'Mrs. Fletcher', enable: true },
      { title: 'The Night Of', enable: false },
      { title: 'Succession', enable: true },
    ],
    charts: {
      hex: '#a17dc8',
      rgb: '161,125,200',
      performance: {
        titles: ['ABR 2021', 'MAI 2021', 'JUN 2021','JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022','FEV 2022', 'MAR 2022'],
        values: [2673, 2757, 2847, 2853, 2946, 2985, 3060, 3153, 3276, 3333, 3480, 3564]
      },
      genresChart: {
        titles: ['Drama', 'Suspense','Ação','Documentário', 'Comédia'],
        values: [52,18,11,9,5]
      },
      subgenresChart: {
        titles: ['Livro','Real','Relações','Crime','Investigação','Psicológico','Religião','Politico','Sobrenatural','Família'],
        values: [9, 8, 7, 7, 6, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [11, 11]
      },
      interestChart: {
        titles: ['Demais Players', 'HBO Max'],
        values: {
          approved: [35, 32],
          notapproved: [6, 5],
          standby: [59, 63]
        }
      }
    }
  },
  filmefilme: {
    id: 200,
    comercialName: 'Filme Filme',
    price: 10,
    moviePercent: 2,
    seriePercent: 0,
    usersQuantity: '1.227',
    usersReturn: 78, //falta
    recommendationsQuantity: '19.053',
    likedQuantity: '84',
    unlikedQuantity: '18',
    notTodayQuantity: '51',
    notForeverQuantity: 0,
    retentionRate: '100',
    churn: 0,
    mrr: '12.270,00',
    mostRecommendedTitles: [
      { title: 'O que os homens falam', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Dentro da Casa', enable: true },
      { title: 'Um Reencontro', enable: true },
      { title: 'Acima das Nuvens', enable: false },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'Tudo pelo Poder', enable: false },
      { title: 'A Casa que Jack Construiu', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'The China Hustle', enable: false },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'O que os homens falam', enable: true },
      { title: 'Acima das Nuvens', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Por que você partiu?', enable: false },
      { title: 'Tudo pelo Poder', enable: false },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
    ],
    charts: {
      hex: '#dd930d',
      rgb: '221,147,13',
      performance: {
        titles: ['ABR 2021','MAI 2021','JUN 2021','JUL 2021', 'AGO 2021','SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022','FEV 2022', 'MAR 2022'],
        values: [969, 999, 1023, 1029, 1041, 1047, 1062, 1077, 1113, 1146, 1191, 1227]
      },
      genresChart: {
        titles: ['Drama','Comédia', 'Documentário', 'Suspense'],
        values: [70,15,11,4]
      },
      subgenresChart: {
        titles: ['Relações','Europa','Independente','Família','Casal','Dramédia','Crime','Itália','Investigação','França'],
        values: [11, 8, 6, 6, 6, 5, 5, 5, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Filme Filme'],
        values: {
          approved: [35, 30],
          notapproved: [6, 5],
          standby: [59, 65]
        }
      }
    }
  },
  globoplay: {
    id: 29,
    comercialName: 'Globoplay',
    price: 22.9,
    moviePercent: 7,
    seriePercent: 10,
    usersQuantity: '240',
    usersReturn: 69, //falta
    recommendationsQuantity: '67.306',
    likedQuantity: '681',
    unlikedQuantity: '108',
    notTodayQuantity: '585',
    notForeverQuantity: 33,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '5.496,00',
    mostRecommendedTitles: [
      { title: 'O Milagre na Cela 7 ', enable: true },
      { title: 'Flesh and Blood: Um Crime Na Vizinhança', enable: true },
      { title: 'Expresso do Amanhã', enable: true },
      { title: 'O Labirinto', enable: true },
      { title: 'Iris', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Julieta', enable: true },
      { title: 'Me chame pelo seu nome', enable: true },
      { title: 'Shirley', enable: true },
      { title: 'Departure - 1ª Temporada', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Million Little Things', enable: true },
      { title: 'Assédio', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Me chame pelo seu nome', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: 'Dunkirk', enable: true },
      { title: 'Expresso do Amanhã ', enable: true },
      { title: 'Amorteamo', enable: true },
      { title: '11/9 - A Vida sob Ataque', enable: true },
      { title: 'The Voice Brasil - 1ª Temporada', enable: true },
    ],
    charts: {
      hex: '#fb1f1f',
      rgb: '251,31,31',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022'],
        values: [0, 45, 162, 240]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Documentário', 'Ação'],
        values: [64,17,11,5,2]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Oscar', 'Livro', 'Familia', 'Dramedia', 'Europa', 'Lgbt', 'Real', 'Independente', 'Itália'],
        values: [9, 8, 6, 6, 5, 5, 5, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [7, 5]
      },
      interestChart: {
        titles: ['Demais Players', 'Globoplay'],
        values: {
          approved: [35, 33],
          notapproved: [6, 6],
          standby: [59, 61]
        }
      }
    }
  },
  disney: {
    id: 202,
    comercialName: 'Disney+',
    price: 27.9,
    moviePercent: 5,
    seriePercent: 3,
    usersQuantity: '291',
    usersReturn: 69, //falta
    recommendationsQuantity: '22.641',
    likedQuantity: '420',
    unlikedQuantity: '33',
    notTodayQuantity: '111',
    notForeverQuantity: 30,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '8.118,90',
    mostRecommendedTitles: [
      { title: 'A Melhor Ideia', enable: true },
      { title: 'Viúva Negra', enable: true },
      { title: 'Milagre do Rio Hudson', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A História da Pixar', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'Império dos Sonhos', enable: true },
      { title: 'Amor, Sublime Amor', enable: true },
      { title: 'Cruella', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Vingadores - Ultimato', enable: true },
      { title: 'Dumbo', enable: true },
      { title: 'Toy Story 4', enable: true },
      { title: 'O Rei Leão', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'WandaVision', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'Viva - A Vida é uma Festa', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'The Mandalorian', enable: true },
    ],
    charts: {
      hex: '#64bee7',
      rgb: '100,190,231',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022'],
        values: [0, 63, 201, 291]
      },
      genresChart: {
        titles: ['Ação','Animação', 'Documentário', 'Drama'],
        values: [55,34,9,2]
      },
      subgenresChart: {
        titles: ['Heroi', 'Marvel', 'Disey', 'Fantasia', 'Aventura', 'Oscar', 'Nostalgia', 'Real', 'Perseguicao', 'Tecnologia'],
        values: [13, 13, 13, 10, 10, 8, 4, 3, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 3]
      },
      interestChart: {
        titles: ['Demais Players', 'Disney+'],
        values: {
          approved: [35, 59],
          notapproved: [6, 5],
          standby: [59, 36]
        }
      }
    }
  },
  star: {
    id: 213,
    comercialName: 'Star+',
    price: 32.9,
    moviePercent: 6,
    seriePercent: 4,
    usersQuantity: '255',
    usersReturn: 69, //falta
    recommendationsQuantity: '41.589',
    likedQuantity: '927',
    unlikedQuantity: '99',
    notTodayQuantity: '438',
    notForeverQuantity: 39,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '8.389,50',
    mostRecommendedTitles: [
      { title: 'A Cabana', enable: true },
      { title: 'Três Anúncios para um Crime', enable: true },
      { title: 'Her', enable: true },
      { title: 'Jojo Rabbit', enable: true },
      { title: 'Only Murders in the Building', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Memórias do 11 de Setembro', enable: true },
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Jogo do Dinheiro', enable: true },
      { title: 'A Favorita', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Bohemian Rhapsody', enable: true },
      { title: 'Her', enable: true },
      { title: 'This is Us', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'A Favorita', enable: true },
      { title: 'Vidro', enable: true },
      { title: 'Ford vs Ferrari', enable: true },
      { title: 'Mercado de Capitais', enable: true },
      { title: 'Judy', enable: true },
    ],
    charts: {
      hex: '#c40f60',
      rgb: '196,15,96',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022'],
        values: [0, 45, 183, 255]
      },
      genresChart: {
        titles: ['Drama', 'Ação', 'Ficção', 'Comédia', 'Documentário'],
        values: [56,22,11,8,2]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Oscar', 'Familia', 'Real', 'Heroi', 'Shyamalan', 'Casal', 'Marvel', 'Livro', 'Tecnologia'],
        values: [14, 9, 8, 6, 6, 5, 5, 5, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Star+'],
        values: {
          approved: [35, 46],
          notapproved: [6, 5],
          standby: [59, 49]
        }
      }
    }
  },
  googleplay: {
    id: 153,
    comercialName: 'Google Play',
    price: '-',
    moviePercent: 5,
    seriePercent: 1,
    usersQuantity: '93',
    usersReturn: 69, //falta
    recommendationsQuantity: '38.305',
    likedQuantity: '501',
    unlikedQuantity: '72',
    notTodayQuantity: '318',
    notForeverQuantity: 165,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '-',
    mostRecommendedTitles: [
      { title: 'O Apartamento', enable: true },
      { title: 'Luta por Justiça', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'Amor, Drogas e Nova York', enable: true },
      { title: 'Blind', enable: true },
      { title: 'Steve Jobs', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'Noite Passada em Soho', enable: true },
      { title: 'Corpo e Alma', enable: true },
      { title: 'Happy End', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Vice', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'Amor ou Consequência', enable: true },
      { title: 'Luta por Justiça', enable: true },
      { title: 'O Homem Duplicado', enable: true },
      { title: 'A Luz entre Oceanos', enable: true },
      { title: 'Kiki', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'O Apartamento', enable: true },
      { title: 'Whitney: Can I be me', enable: false },
    ],
    charts: {
      hex: '#07c263',
      rgb: '7,194,99',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022'],
        values: [0, 21, 66, 93]
      },
      genresChart: {
        titles: ['Drama', 'Comédia', 'Suspense', 'Documentário'],
        values: [85,7,6,1]
      },
      subgenresChart: {
        titles: ['Relacoes','Livro','Real','Independente','Casal','Familia','Oscar','Dramedia','Cannes','Investigacao'],
        values: [12, 8, 7, 7, 7, 6, 5, 4, 4, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 3]
      },
      interestChart: {
        titles: ['Demais Players', 'Google Play'],
        values: {
          approved: [35, 23],
          notapproved: [6, 3],
          standby: [59, 74]
        }
      }
    }
  },
};