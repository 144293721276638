import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";



@Injectable()
export class AuthService {
    constructor(){}

    isLoggedIn() {
        return sessionStorage.getItem('user');
    }

    login(email, password) {
        let validated = false;
        environment.logins.forEach(element => {
            console.log('asas');
            if (email === element.email && password === element.password) {
                console.log('aaaaaa');
                sessionStorage.setItem('user', JSON.stringify({'user' : 'default'}));
                validated = true;
            }
        });

        return validated;
    }

    logout() {
        sessionStorage.removeItem('user');
    }
}