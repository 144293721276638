import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class StreamingService {
    constructor(){}
    
    public editStreaming: any = [];
    public editPeriod: string = '';
    public subject = new Subject<any>();
    private streamingSource = new BehaviorSubject(this.editStreaming);
    private periodSource = new BehaviorSubject(this.editPeriod);

    currentStreaming = this.streamingSource.asObservable();
    currentPeriod = this.periodSource.asObservable();

    changeStreaming(streaming: string) {
        this.streamingSource.next(streaming);
    }

    changePeriod(period: string) {
        this.periodSource.next(period);
    }
}