// 03/2023
export const TwentyTwentyThreeMarch = {
  viureview: {
    totalUsersQuantity: '217.630', // André
    moviePercent: 70,
    seriePercent: 30,
    usersQuantity: '16.095',
    usersReturn: 73, // André
    recommendationsQuantity: '1.455.695',
    likedQuantity: '37.269',
    unlikedQuantity: '7.353',
    notTodayQuantity: '10.203',
    notForeverQuantity: 1941,
    mostRecommendedTitles: [
      { title: 'Ataque dos Cães (Netflix)', enable: true },
      { title: 'O Caso Collini (Netflix)', enable: true },
      { title: 'Osmosis (Netflix)', enable: true },
      { title: 'Buscando... (Netflix)', enable: true },
      { title: 'Entre Facas e Segredos (Prime Video)', enable: true },
      { title: '13 Reasons Why (Netflix)', enable: true },
      { title: 'American Son (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Areia Movediça (Netflix)', enable: true },
      { title: 'Good Girls (Netflix)', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino (Star+)', enable: true },
      { title: 'Case (Netflix)', enable: true },
      { title: 'Mytho (Netflix)', enable: true },
      { title: 'Se eu não tivesse te conhecido (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Roman J. Israel, Esq. (Netflix)', enable: true },
      { title: 'Acredite em Mim: A História de Lisa McVey (Netflix)', enable: true },
      { title: 'Califado (Netflix)', enable: true },
      { title: 'Segurança em Jogo (Netflix)', enable: true },
      { title: 'The Sinner - 1ª Temporada (Netflix)', enable: true },
    ],
    charts: {
      hex: '#F36300',
      rgb: '243,99,0',
      performance: { // André
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022','JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [140055, 146137, 151198, 156845, 162767, 169752, 175281, 182088, 187931, 196814, 206173, 217630]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Ficção','Comédia'],
        values: [34,18,17,16,9]
      },
      subgenresChart: {
        titles: ['Relacoes','Crime','Investigacao','Livro','Psicologico','Real','Europa','Tecnologia','Familia','Oscar'],
        values: [8,7,6,5,5,4,4,4,3,3]
      },
      usersByStreaming: {
        titles: ['NETFLIX', 'PRIME VIDEO', 'HBO Max', 'TELECINE', 'APPLE TV', 'DISNEY+', 'FILME FILME', 'STAR+', 'GLOBOPLAY', 'GOOGLE PLAY'],
        values: [81, 59, 31, 20, 19, 12, 11, 10, 10, 5]
      }
    }
  },
  netflix: {
    id: 27,
    comercialName: 'Netflix',
    price: 39.9,
    moviePercent: 30,
    seriePercent: 48,
    usersQuantity: '13.485',
    usersReturn: 69, //falta
    recommendationsQuantity: '600.066',
    likedQuantity: '22.818',
    unlikedQuantity: '4.653',
    notTodayQuantity: '2.316',
    notForeverQuantity: 867,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '538.051,50',
    mostRecommendedTitles: [
      { title: 'Ataque dos Cães', enable: true },
      { title: 'O Caso Collini', enable: true },
      { title: 'Osmosis', enable: true },
      { title: 'Buscando...', enable: true },
      { title: 'Entre Facas e Segredos', enable: true },
      { title: '13 Reasons Why', enable: true },
      { title: 'American Son', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Areia Movediça', enable: true },
      { title: 'Good Girls', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Case', enable: true },
      { title: 'Mytho', enable: true },
      { title: 'Se eu não tivesse te conhecido', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Roman J. Israel, Esq.', enable: true },
      { title: 'Acredite em Mim: A História Lisa McVey', enable: true },
      { title: 'Califado', enable: true },
      { title: 'Segurança em Jogo', enable: true },
      { title: 'The Sinner - 1ª Temporada', enable: true },
      { title: 'Fique Rico ou Morra Tentando', enable: true },
    ],
    charts: {
      hex: '#cf1753',
      rgb: '184,25,77',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [11706, 11802, 11949, 12228, 12507, 12612, 12765, 12900, 13035, 13170, 13302, 13485]
      },
      genresChart: {
        titles: ['Drama','Ficção', 'Suspense','Ação','Comédia'],
        values: [27,22,19,17,9]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Crime', 'Investigação','Livro','Psicologico','Europa','Tecnologia','Real','Perseguicao', 'Dramedia'],
        values: [8, 8, 7, 6, 5, 5, 4, 3, 3, 2]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [43, 48]
      },
      interestChart: {
        titles: ['Demais Players', 'Netflix'],
        values: {
          approved: [30, 34],
          notapproved: [6, 7],
          standby: [64, 59]
        }
      }
    }
  },
  telecine: {
    id: 201,
    comercialName: 'Telecine',
    price: 29.9,
    moviePercent: 2,
    seriePercent: 0,
    usersQuantity: '3.402',
    usersReturn: 78, //falta
    recommendationsQuantity: '21.452',
    likedQuantity: '864',
    unlikedQuantity: '186',
    notTodayQuantity: '363',
    notForeverQuantity: 36,
    retentionRate: '100',
    churn: 0,
    mrr: '101.719,80',
    mostRecommendedTitles: [
      { title: 'Corra', enable: true },
      { title: 'Em Defesa de Cristo', enable: true },
      { title: 'Noite Passada em Soho', enable: true },
      { title: 'O Escândalo', enable: true },
      { title: 'Magnatas do Crime ', enable: true },
      { title: 'Belfast', enable: true },
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'Midsommar', enable: true },
      { title: 'A Esposa', enable: true },
      { title: 'Retrato de uma Jovem em Chamas', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'O Escândalo', enable: true },
      { title: 'Midsommar', enable: true },
      { title: 'Hebe - A estrela do Brasil', enable: true },
      { title: 'Em Defesa de Cristo', enable: true },
      { title: 'Corra', enable: true },
      { title: 'A Esposa', enable: true },
      { title: 'Magnatas do Crime', enable: true },
      { title: 'Retrato de uma Jovem em Chamas', enable: true },
      { title: 'O Telefone Preto', enable: true },
    ],
    charts: {
      hex: '#4a70a9',
      rgb: '74,112,169',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [2577, 2626, 2649, 2776, 2904, 2982, 3048, 3117, 3186, 3255, 3324, 3402]
      },
      genresChart: {
        titles: ['Suspense', 'Drama','Ação'],
        values: [67,30,3]
      },
      subgenresChart: {
        titles: ['Psicologico', 'Sobrenatural', 'Relacoes', 'Oscar', 'Crime', 'Racismo', 'Livro', 'Religião', 'Real', 'Terrorismo'],
        values: [13, 13, 9, 9, 8, 8, 8, 6, 4, 2]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Telecine'],
        values: {
          approved: [32, 43],
          notapproved: [6, 9],
          standby: [62, 48]
        }
      }
    }
  },
  apple: {
    id: 59,
    comercialName: 'Apple TV',
    price: 14.9,
    moviePercent: 7,
    seriePercent: 8,
    usersQuantity: '3.261',
    usersReturn: 78, //falta
    recommendationsQuantity: '98.819',
    likedQuantity: '1.083',
    unlikedQuantity: '213',
    notTodayQuantity: '1239',
    notForeverQuantity: 135,
    retentionRate: '100',
    churn: 0,
    mrr: '48.588,90',
    mostRecommendedTitles: [
      { title: 'Calls', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Em defesa de Jacob', enable: true },
      { title: 'Losing Alice', enable: true },
      { title: 'Três Estranhos Idênticos', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Palmer', enable: true },
      { title: 'O Canto do Cisne', enable: true },
      { title: 'Verdade seja dita', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Morning Show', enable: true },
      { title: 'For All Mankind', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'Servant', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'See', enable: true },
      { title: 'Azul é a cor mais quente', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Kursk', enable: true },
    ],
    charts: {
      hex: '#c4c4c4',
      rgb: '196,196,196',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [2397, 2457, 2508, 2638, 2769, 2846, 2901, 2969, 3037, 3105, 3171, 3261]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Suspense','Ação'],
        values: [72,11,8,4,3]
      },
      subgenresChart: {
        titles: ['Relações','Casal','Investigação','Familia','Independente','Crime','Livro','Cannes','Real','Oscar'],
        values: [13, 11, 7, 7, 6, 6, 6, 6, 5, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [7, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Apple TV'],
        values: {
          approved: [33, 21],
          notapproved: [6, 4],
          standby: [61, 75]
        }
      }
    }
  },
  prime: {
    id: 37,
    comercialName: 'Prime Video',
    price: 14.9,
    moviePercent: 21,
    seriePercent: 9,
    usersQuantity: '9.903',
    usersReturn: 78, //falta
    recommendationsQuantity: '255.566',
    likedQuantity: '4.881',
    unlikedQuantity: '804',
    notTodayQuantity: '945',
    notForeverQuantity: 351,
    retentionRate: '100',
    churn: 0,
    mrr: '147.554,70',
    mostRecommendedTitles: [
      { title: 'DOM', enable: true },
      { title: 'A Grande Mentira', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Capote', enable: true },
      { title: 'Ex-Machina', enable: true },
      { title: 'O Passado', enable: true },
      { title: 'Precisamos falar sobre o Kevin!', enable: true },
      { title: 'Sou sua Mulher', enable: true },
      { title: 'Uma razão para viver', enable: true },
      { title: 'A Grande Jogada', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Creed 2', enable: true },
      { title: 'Blue Valentine', enable: true },
      { title: 'Green Book', enable: true },
      { title: 'Vice', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Dor e Glória', enable: true },
      { title: 'The Boys', enable: true },
      { title: 'Verdade e Justiça', enable: true },
      { title: 'O Caso Richard Jewell', enable: true },
      { title: 'Anna', enable: true },
    ],
    charts: {
      hex: '#18a0c8',
      rgb: '24,160,200',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [8418, 8514, 8619, 8872, 9126, 9189, 9333, 9443, 9553, 9663, 9771, 9903]
      },
      genresChart: {
        titles: ['Drama','Ação','Ficção','Suspense','Comédia'],
        values: [42,22,14,11,10]
      },
      subgenresChart: {
        titles: ['Relações','Real','Oscar','Livro','Investigação','Crime','Tecnologia','Família','Psicológico','Terrorismo'],
        values: [10,6,6,5,4,4,4,4,3,3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [18, 13]
      },
      interestChart: {
        titles: ['Demais Players', 'Prime Video'],
        values: {
          approved: [33, 30],
          notapproved: [6, 5],
          standby: [61, 65]
        }
      }
    }
  },
  hbo: {
    id: 36,
    comercialName: 'HBO Max',
    price: 34.9,
    moviePercent: 14,
    seriePercent: 14,
    usersQuantity: '5.160',
    usersReturn: 78, //falta
    recommendationsQuantity: '163.710',
    likedQuantity: '2.169',
    unlikedQuantity: '396',
    notTodayQuantity: '882',
    notForeverQuantity: 138,
    retentionRate: '100',
    churn: 0,
    mrr: '180.084,00',
    mostRecommendedTitles: [
      { title: 'Rainhas do Crime', enable: true },
      { title: 'The Undoing', enable: true },
      { title: 'Relatos Selvagens', enable: true },
      { title: 'Mare of Easttown', enable: true },
      { title: '3096 Dias', enable: true },
      { title: 'Caminhos da Memória', enable: true },
      { title: 'Florida Project', enable: true },
      { title: 'O Pintassilgo', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Mrs. Fletcher', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Jinx: The Life and Deaths of Robert Durst', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'A Vida Depois ', enable: true },
      { title: 'O Primeiro Milhão', enable: true },
      { title: 'A Batalha das Correntes', enable: false },
      { title: 'Sharp Objects', enable: true },
      { title: 'Euphoria', enable: true },
      { title: 'Coringa ', enable: true },
      { title: 'Nasce uma Estrela', enable: true },
      { title: 'Chernobyl ', enable: true },
    ],
    charts: {
      hex: '#a17dc8',
      rgb: '161,125,200',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [3780, 3849, 3954, 4183, 4413, 4504, 4614, 4717, 4820, 4923, 5025, 5160]
      },
      genresChart: {
        titles: ['Drama', 'Ação','Documentário', 'Comédia', 'Suspense'],
        values: [61,16,7,6,5]
      },
      subgenresChart: {
        titles: ['Real', 'Relacoes', 'Crime', 'Investigacao', 'Oscar', 'Familia', 'Livro', 'Violência', 'Político', 'Tecnologia'],
        values: [9, 7, 7, 6, 6, 5, 4, 3, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [12, 12]
      },
      interestChart: {
        titles: ['Demais Players', 'HBO Max'],
        values: {
          approved: [33, 27],
          notapproved: [6, 5],
          standby: [61, 68]
        }
      }
    }
  },
  filmefilme: {
    id: 200,
    comercialName: 'Filme Filme',
    price: 12,
    moviePercent: 2,
    seriePercent: 0,
    usersQuantity: '1.878',
    usersReturn: 78, //falta
    recommendationsQuantity: '19.232',
    likedQuantity: '84',
    unlikedQuantity: '39',
    notTodayQuantity: '111',
    notForeverQuantity: 0,
    retentionRate: '100',
    churn: 0,
    mrr: '22.536,00',
    mostRecommendedTitles: [
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Julho Agosto', enable: true },
      { title: 'A Arte de Amar', enable: true },
      { title: '10.000 KM', enable: true },
      { title: 'Mil Vezes Boa Noite', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Dafne', enable: true },
      { title: 'Café de Flore', enable: true },
      { title: 'Jovem e Bela', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: '10.000 Km', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Julho Agosto', enable: true },
    ],
    charts: {
      hex: '#dd930d',
      rgb: '221,147,13',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [1281, 1314, 1338, 1428, 1518, 1589, 1614, 1667, 1720, 1773, 1824, 1878]
      },
      genresChart: {
        titles: ['Drama','Suspense', 'Comédia'],
        values: [68,17,15]
      },
      subgenresChart: {
        titles: ['Relacoes','Europa','Casal','Familia','Independente','Crime','Franca','Livro','Romântica','Italia'],
        values: [12, 9, 8, 7, 5, 5, 5, 5, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [1, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Filme Filme'],
        values: {
          approved: [32, 20],
          notapproved: [6, 10],
          standby: [62, 70]
        }
      }
    }
  },
  globoplay: {
    id: 29,
    comercialName: 'Globoplay',
    price: 19.9,
    moviePercent: 4,
    seriePercent: 9,
    usersQuantity: '1.575',
    usersReturn: 69, //falta
    recommendationsQuantity: '60.342',
    likedQuantity: '519',
    unlikedQuantity: '102',
    notTodayQuantity: '567',
    notForeverQuantity: 21,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '31.342,50',
    mostRecommendedTitles: [
      { title: 'O Milagre na Cela 7', enable: true },
      { title: 'Expresso do Amanhã', enable: true },
      { title: 'Iris', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'O Labirinto', enable: true },
      { title: 'Shirley', enable: true },
      { title: 'Departure - 1ª Temporada', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: 'A Million Little Things', enable: true },
      { title: 'Minhas Mães e Meu Pai', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Por que as Mulheres Matam', enable: true },
      { title: 'A Million Little Things', enable: true },
      { title: 'Assédio', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: '11/9 - A Vida sob Ataque', enable: true },
      { title: 'Expresso do Amanhã ', enable: true },
      { title: 'Nudes', enable: true },
      { title: 'Uma Prova de Amor', enable: true },
      { title: 'O Milagre na Cela 7', enable: true },
    ],
    charts: {
      hex: '#fb1f1f',
      rgb: '251,31,31',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [417, 495, 576, 768, 960, 1038, 1125, 1211, 1297, 1383, 1467, 1575]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Documentário', 'Ação'],
        values: [48,27,15,7,1]
      },
      subgenresChart: {
        titles: ['Dramedia','Livro','Relacoes','Violencia','Politico','Asia','Coreia','Cannes','Oscar','Familia'],
        values: [8, 8, 7, 6, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 4]
      },
      interestChart: {
        titles: ['Demais Players', 'Globoplay'],
        values: {
          approved: [33, 19],
          notapproved: [6, 4],
          standby: [61, 77]
        }
      }
    }
  },
  disney: {
    id: 202,
    comercialName: 'Disney+',
    price: 27.9,
    moviePercent: 5,
    seriePercent: 2,
    usersQuantity: '1.962',
    usersReturn: 69, //falta
    recommendationsQuantity: '29.837',
    likedQuantity: '747',
    unlikedQuantity: '72',
    notTodayQuantity: '138',
    notForeverQuantity: 33,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '54.739,80',
    mostRecommendedTitles: [
      { title: 'A Melhor Ideia', enable: true },
      { title: 'Viúva Negra', enable: true },
      { title: 'Milagre do Rio Hudson', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A História da Pixar', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'Amor, Sublime Amor', enable: true },
      { title: 'Império dos Sonhos', enable: true },
      { title: 'Cruella', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Homem-Aranha no Aranhaverso', enable: true },
      { title: 'Vingadores - Ultimato', enable: true },
      { title: 'Dumbo', enable: true },
      { title: 'Toy Story 4', enable: true },
      { title: 'O Rei Leão', enable: true },
      { title: 'Estrada para a Glória', enable: true },
      { title: 'WandaVision', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Viva - A Vida é uma Festa', enable: true },
    ],
    charts: {
      hex: '#64bee7',
      rgb: '100,190,231',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [522, 612, 717, 952, 1188, 1248, 1395, 1506, 1617, 1728, 1839, 1962]
      },
      genresChart: {
        titles: ['Ação','Animação', 'Documentário', 'Drama'],
        values: [67,26,6,1]
      },
      subgenresChart: {
        titles: ['Marvel', 'Heroi', 'Disney', 'Oscar', 'Aventura', 'Fantasia', 'Perseguicao', 'Nostalgia', 'Pantera', 'Tecnologia'],
        values: [17, 16, 10, 7, 7, 6, 4, 4, 4, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 3]
      },
      interestChart: {
        titles: ['Demais Players', 'Disney+'],
        values: {
          approved: [32, 45],
          notapproved: [6, 5],
          standby: [62, 50]
        }
      }
    }
  },
  star: {
    id: 213,
    comercialName: 'Star+',
    price: 32.9,
    moviePercent: 8,
    seriePercent: 9,
    usersQuantity: '1.716',
    usersReturn: 69, //falta
    recommendationsQuantity: '76.027',
    likedQuantity: '2.046',
    unlikedQuantity: '384',
    notTodayQuantity: '939',
    notForeverQuantity: 87,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '56.456,40',
    mostRecommendedTitles: [
      { title: 'A Cabana', enable: true },
      { title: 'Três Anúncios para um Crime', enable: true },
      { title: 'Projeto Gemini', enable: true },
      { title: 'Only Murders in the Building', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'Jojo Rabbit', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Memórias do 11 de Setembro', enable: true },
      { title: 'Nerve', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Bohemian Rhapsody', enable: true },
      { title: 'This is Us', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'A Favorita', enable: true },
      { title: 'Vidro', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Projeto Gemini', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'Ford vs Ferrari', enable: true },
    ],
    charts: {
      hex: '#c40f60',
      rgb: '196,15,96',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [453, 525, 618, 835, 1053, 1142, 1230, 1323, 1416, 1509, 1602, 1716]
      },
      genresChart: {
        titles: ['Ação', 'Drama', 'Comédia', 'Suspense', 'Documentario'],
        values: [52, 37, 5, 4, 1]
      },
      subgenresChart: {
        titles: ['Tecnologia', 'Relacoes','Jovem','Games','Crime','Real','Shyamalan','Herói','Livro','Oscar'],
        values: [10, 8, 8, 8, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [5, 7]
      },
      interestChart: {
        titles: ['Demais Players', 'Star+'],
        values: {
          approved: [32, 41],
          notapproved: [6, 8],
          standby: [62, 51]
        }
      }
    }
  },
  googleplay: {
    id: 153,
    comercialName: 'Google Play',
    price: '-',
    moviePercent: 6,
    seriePercent: 0,
    usersQuantity: '768',
    usersReturn: 69, //falta
    recommendationsQuantity: '60.806',
    likedQuantity: '1.236',
    unlikedQuantity: '342',
    notTodayQuantity: '1.350',
    notForeverQuantity: 126,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '-',
    mostRecommendedTitles: [
      { title: 'O Apartamento', enable: true },
      { title: 'Amor, Drogas e Nova York', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'Blind', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'O Preço da Verdade', enable: true },
      { title: 'Em Pedaços', enable: true },
      { title: 'Steve Jobs', enable: true },
      { title: 'Corpo e Alma', enable: true },
      { title: 'Kiki', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Parasita', enable: true },
      { title: 'Bata antes de Entrar', enable: true },
      { title: '1917', enable: true },
      { title: 'Yesterday', enable: true },
      { title: 'Mercado de Capitais', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'Amor ou Consequência', enable: true },
      { title: 'O Homem Duplicado', enable: true },
      { title: 'A Luz entre Oceanos', enable: true },
      { title: 'Em Pedaços', enable: true },
    ],
    charts: {
      hex: '#07c263',
      rgb: '7,194,99',
      performance: {
        titles: ['ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023', 'MAR 2023'],
        values: [162, 162, 231, 271, 411, 456, 501, 555, 609, 663, 714, 768],
      },
      genresChart: {
        titles: ['Suspense', 'Drama', 'Comédia', 'Ação'],
        values: [48, 38, 11, 3]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Violencia', 'Psicologico', 'Casal', 'Aronofsky', 'Religiao', 'Independente', 'Assedio', 'Oscar', 'Familia'],
        values: [13, 10, 9, 8, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 4]
      },
      interestChart: {
        titles: ['Demais Players', 'Google Play'],
        values: {
          approved: [32, 28],
          notapproved: [6, 8],
          standby: [62, 64]
        }
      }
    }
  }
};