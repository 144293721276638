// 06/2022
export const TwentyTwentyTwoJune = {
  viureview: {
    totalUsersQuantity: '151.198', // André
    moviePercent: 69,
    seriePercent: 31,
    usersQuantity: '13.599',
    usersReturn: 73, // André
    recommendationsQuantity: '1.187.344',
    likedQuantity: '34.377',
    unlikedQuantity: '6.234',
    notTodayQuantity: '7.197',
    notForeverQuantity: 1.914,
    mostRecommendedTitles: [
      { title: 'O Caso Collini (Netflix)', enable: true },
      { title: 'Buscando... (Netflix)', enable: true },
      { title: 'Ataque dos Cães (Netflix)', enable: true },
      { title: 'Osmosis (Netflix)', enable: true },
      { title: 'Entre Facas e Segredos (Prime Video)', enable: true },
      { title: 'American Son (Netflix)', enable: true },
      { title: 'Areia Movediça (Netflix)', enable: true },
      { title: 'A Grande Mentira (Telecine)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Amar (Netflix)', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino (Star+)', enable: true },
      { title: 'Case (Netflix)', enable: true },
      { title: 'Mytho (Netflix)', enable: true },
      { title: 'Se eu não tivesse te conhecido (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Creed 2 (Prime Video)', enable: true },
      { title: 'Roman J. Israel, Esq. (Netflix)', enable: true },
      { title: 'Bordertown (Netflix)', enable: true },
      { title: 'Bohemian Rhapsody (Star+)', enable: true },
      { title: 'Diga quem sou (Netflix)', enable: true },
    ],
    charts: {
      hex: '#F36300',
      rgb: '243,99,0',
      performance: { // André
        titles: ['JUL 21', 'AGO 21', 'SET 21', 'OUT 21', 'NOV 21', 'DEZ 21','JAN 22', 'FEV 22', 'MAR 22', 'ABR 22', 'MAI 22', 'JUN 22'],
        values: [90106, 92237, 96793, 107273, 112369, 115457, 121559, 127837, 134682, 140055, 146137, 151198]
      },
      genresChart: {
        titles: ['Drama','Suspense','Ficção','Ação','Comédia'],
        values: [35,18,17,17,8]
      },
      subgenresChart: {
        titles: ['Relacoes','Crime','Investigacao','Livro','Psicologico','Real','Europa','Tecnologia','Familia','Oscar'],
        values: [8,7,6,6,5,4,4,4,3,3]
      },
      usersByStreaming: {
        titles: ['NETFLIX', 'PRIME VIDEO', 'HBO Max', 'APPLE TV', 'TELECINE', 'FILME FILME', 'GLOBOPLAY', 'DISNEY+', 'STAR+', 'GOOGLE PLAY'],
        values: [86, 62, 28, 18, 18, 9, 4, 5, 4, 2]
      }
    }
  },
  netflix: {
    id: 27,
    comercialName: 'Netflix',
    price: 39.9,
    moviePercent: 29,
    seriePercent: 49,
    usersQuantity: '11.949',
    usersReturn: 69, //falta
    recommendationsQuantity: '464.202',
    likedQuantity: '20.529',
    unlikedQuantity: '4.074',
    notTodayQuantity: '2.424',
    notForeverQuantity: 810,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '476.765,10',
    mostRecommendedTitles: [
      { title: 'O Caso Collini', enable: true },
      { title: 'Buscando...', enable: true },
      { title: 'Ataque dos Cães', enable: true },
      { title: 'Osmosis', enable: true },
      { title: 'American Son', enable: true },
      { title: 'Areia Movediça', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Amar', enable: true },
      { title: 'Good Girls', enable: true },
      { title: 'The One', enable: true }
    ],
    mostInterestedTitles: [
      { title: 'Case', enable: true },
      { title: 'Mytho', enable: true },
      { title: 'Se eu não tivesse te conhecido', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Roman J. Israel, Esq.', enable: true },
      { title: 'Bordertown', enable: true },
      { title: 'Diga quem sou', enable: true },
      { title: 'Era uma Segunda Vez', enable: true },
      { title: 'American Son', enable: true },
      { title: 'The Sinner - 1ª Temporada', enable: true },
    ],
    charts: {
      hex: '#cf1753',
      rgb: '184,25,77',
      performance: {
        titles: ['JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021','NOV 2021','DEZ 2021', 'JAN 2022', 'FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [10443, 10575, 10611, 10734, 10854, 10992, 11058, 11247, 11349, 11706, 11802, 11949]
      },
      genresChart: {
        titles: ['Drama','Ficção', 'Suspense','Ação','Comédia'],
        values: [28,22,19,15,9]
      },
      subgenresChart: {
        titles: ['Crime', 'Investigação','Relacoes','Europa','Livro','Psicologico','Tecnologia','Real','Perseguicao', 'Espanha'],
        values: [9, 8, 7, 6, 6, 5, 4, 3, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [41, 49]
      },
      interestChart: {
        titles: ['Demais Players', 'Netflix'],
        values: {
          approved: [34, 35],
          notapproved: [5, 7],
          standby: [61, 58]
        }
      }
    }
  },
  telecine: {
    id: 201,
    comercialName: 'Telecine',
    price: 37.9,
    moviePercent: 3,
    seriePercent: 0,
    usersQuantity: '2.649',
    usersReturn: 78, //falta
    recommendationsQuantity: '28.306',
    likedQuantity: '1.032',
    unlikedQuantity: '252',
    notTodayQuantity: '186',
    notForeverQuantity: 132,
    retentionRate: '100',
    churn: 0,
    mrr: '100.397,10',
    mostRecommendedTitles: [
      { title: 'A Grande Mentira', enable: true },
      { title: 'Luta por Justiça', enable: true },
      { title: 'O Caso Richard Jewell', enable: true },
      { title: 'Em Defesa de Cristo', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'Parasita', enable: true },
      { title: 'É apenas o Fim do Mundo', enable: true },
      { title: 'O melhor está por vir', enable: true },
      { title: 'Magnatas do Crime ', enable: true },
      { title: 'Coringa', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Parasita', enable: true },
      { title: 'Coringa', enable: true },
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'IT - Capítulo II', enable: true },
      { title: 'O Caso Richard Jewell', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'Aves de Rapina', enable: true },
      { title: 'A Grande Mentira', enable: true },
      { title: 'Luta por Justiça', enable: true },
      { title: 'Hebe - A estrela do Brasil', enable: true },
    ],
    charts: {
      hex: '#4a70a9',
      rgb: '74,112,169',
      performance: {
        titles: ['JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [2118, 2148, 2169, 2211, 2271, 2334, 2364, 2445, 2490, 2577, 2626, 2649]
      },
      genresChart: {
        titles: ['Suspense', 'Drama','Ação', 'Comédia'],
        values: [51,32,12,5]
      },
      subgenresChart: {
        titles: ['Relações','Violência','Psicológico','Livro','Casal','Religião','Aronofsky','Jovem','Stephen King','Sobrenatural'],
        values: [9, 8, 7, 7, 6, 5, 5, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 3]
      },
      interestChart: {
        titles: ['Demais Players', 'Telecine'],
        values: {
          approved: [35, 38],
          notapproved: [6, 9],
          standby: [59, 53]
        }
      }
    }
  },
  apple: {
    id: 59,
    comercialName: 'Apple TV',
    price: 9.9,
    moviePercent: 7,
    seriePercent: 8,
    usersQuantity: '2.508',
    usersReturn: 78, //falta
    recommendationsQuantity: '87.575',
    likedQuantity: '1125',
    unlikedQuantity: '150',
    notTodayQuantity: '585',
    notForeverQuantity: 147,
    retentionRate: '100',
    churn: 0,
    mrr: '24.829,20',
    mostRecommendedTitles: [
      { title: 'Calls', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Em defesa de Jacob', enable: true },
      { title: 'Extraordinário ', enable: true },
      { title: 'Losing Alice', enable: true },
      { title: 'Três Estranhos Idênticos', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Eu, Tonya', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'O Canto do Cisne', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Morning Show', enable: true },
      { title: 'For All Mankind', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Servant', enable: true },
      { title: 'See', enable: true },
      { title: 'Azul é a cor mais quente', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'Kursk', enable: true },
      { title: 'Sombras da Vida', enable: true },
    ],
    charts: {
      hex: '#c4c4c4',
      rgb: '196,196,196',
      performance: {
        titles: ['JUL 2021', 'AGO 2021','SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [1896, 1926, 1944, 1986, 2022, 2109, 2148, 2235, 2286, 2397, 2457, 2508]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Suspense','Ação'],
        values: [79,7,5,4,3]
      },
      subgenresChart: {
        titles: ['Relações','Livro','Casal','Familia','Real','Investigação','Independente','Crime','Cannes','Superacao'],
        values: [14, 9, 8, 7, 5, 5, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [8, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Apple TV'],
        values: {
          approved: [35, 26],
          notapproved: [6, 4],
          standby: [59, 70]
        }
      }
    }
  },
  prime: {
    id: 37,
    comercialName: 'Prime Video',
    price: 9.9,
    moviePercent: 21,
    seriePercent: 10,
    usersQuantity: '8.619',
    usersReturn: 78, //falta
    recommendationsQuantity: '211.648',
    likedQuantity: '5.976',
    unlikedQuantity: '957',
    notTodayQuantity: '1047',
    notForeverQuantity: 342,
    retentionRate: '100',
    churn: 0,
    mrr: '85.328,10',
    mostRecommendedTitles: [
      { title: 'Entre Facas e Segredos', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'DOM', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Capote', enable: true },
      { title: 'Ex-Machina', enable: true },
      { title: 'O Passado', enable: true },
      { title: 'Corra', enable: true },
      { title: 'Precisamos falar sobre o Kevin!', enable: true },
      { title: 'A Grande Jogada', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Creed 2', enable: true },
      { title: 'Blue Valentine', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Green Book', enable: true },
      { title: 'The Boys', enable: true },
      { title: 'Bata antes de Entrar', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Dor e Glória', enable: true },
      { title: 'Transparent', enable: true },
      { title: 'Anna', enable: true },
    ],
    charts: {
      hex: '#18a0c8',
      rgb: '24,160,200',
      performance: {
        titles: ['JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [7323, 7434, 7461, 7557, 7674, 7815, 7869, 8019, 8103, 8418, 8514, 8619]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Ficção','Comédia'],
        values: [37,27,18,11,6]
      },
      subgenresChart: {
        titles: ['Relações','Psicológico','Real','Oscar','Tecnologia','Crime','Investigação','Livro','Família','Religião'],
        values: [9,6,6,6,6,5,4,4,4,3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [19, 13]
      },
      interestChart: {
        titles: ['Demais Players', 'Prime Video'],
        values: {
          approved: [35, 36],
          notapproved: [6, 6],
          standby: [59, 58]
        }
      }
    }
  },
  hbo: {
    id: 36,
    comercialName: 'HBO Max',
    price: 27.9,
    moviePercent: 11,
    seriePercent: 13,
    usersQuantity: '3.954',
    usersReturn: 78, //falta
    recommendationsQuantity: '122.609',
    likedQuantity: '1.722',
    unlikedQuantity: '258',
    notTodayQuantity: '678',
    notForeverQuantity: 93,
    retentionRate: '100',
    churn: 0,
    mrr: '110.316,60',
    mostRecommendedTitles: [
      { title: 'The Undoing', enable: true },
      { title: 'Rainhas do Crime', enable: true },
      { title: 'Mare of Easttown', enable: true },
      { title: 'Relatos Selvagens', enable: true },
      { title: '3096 Dias', enable: true },
      { title: 'Caminhos da Memória', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'Mrs. Fletcher', enable: true },
      { title: 'O Pintassilgo', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Jinx: The Life and Deaths of Robert Durst', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Euphoria', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'Nasce uma Estrela', enable: true },
      { title: 'Chernobyl ', enable: true },
      { title: 'Mrs. Fletcher', enable: true },
      { title: 'The Night Of', enable: false },
      { title: 'Succession', enable: true },
      { title: 'Room 104', enable: true },
    ],
    charts: {
      hex: '#a17dc8',
      rgb: '161,125,200',
      performance: {
        titles: ['JUL 2021', 'AGO 2021', 'SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022','FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [2853, 2946, 2985, 3060, 3153, 3276, 3333, 3480, 3564, 3780, 3849, 3954]
      },
      genresChart: {
        titles: ['Drama', 'Suspense','Ação','Documentário', 'Comédia'],
        values: [47,18,15,9,5]
      },
      subgenresChart: {
        titles: ['Real','Crime','Livro','Investigação','Relações','Psicologico','Religião','Politico','Sobrenatural','Familia'],
        values: [8, 8, 8, 7, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [11, 10]
      },
      interestChart: {
        titles: ['Demais Players', 'HBO Max'],
        values: {
          approved: [35, 33],
          notapproved: [6, 5],
          standby: [59, 62]
        }
      }
    }
  },
  filmefilme: {
    id: 200,
    comercialName: 'Filme Filme',
    price: 10,
    moviePercent: 3,
    seriePercent: 0,
    usersQuantity: '1.338',
    usersReturn: 78, //falta
    recommendationsQuantity: '20.868',
    likedQuantity: '96',
    unlikedQuantity: '24',
    notTodayQuantity: '87',
    notForeverQuantity: 0,
    retentionRate: '100',
    churn: 0,
    mrr: '13.380,00',
    mostRecommendedTitles: [
      { title: 'O que os homens falam', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Dentro da Casa', enable: true },
      { title: 'Um Reencontro', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Nomandia Nua', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'O que os homens falam', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Dentro da Casa', enable: true },
      { title: 'Um Reencontro', enable: true },
      { title: 'Wrong', enable: false },
      { title: '10.000 Km', enable: true },
    ],
    charts: {
      hex: '#dd930d',
      rgb: '221,147,13',
      performance: {
        titles: ['JUL 2021', 'AGO 2021','SET 2021', 'OUT 2021', 'NOV 2021','DEZ 2021','JAN 2022','FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [1029, 1041, 1047, 1062, 1077, 1113, 1146, 1191, 1227, 1281, 1314, 1338]
      },
      genresChart: {
        titles: ['Drama','Comédia', 'Suspense'],
        values: [70,18,12]
      },
      subgenresChart: {
        titles: ['Relações','Europa','Casal','Dramédia','Independente','Família','Itália','Crime','França','Woody Allen'],
        values: [11, 11, 7, 6, 6, 6, 5, 5, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Filme Filme'],
        values: {
          approved: [35, 23],
          notapproved: [6, 6],
          standby: [59, 71]
        }
      }
    }
  },
  globoplay: {
    id: 29,
    comercialName: 'Globoplay',
    price: 22.9,
    moviePercent: 6,
    seriePercent: 12,
    usersQuantity: '576',
    usersReturn: 69, //falta
    recommendationsQuantity: '72.266',
    likedQuantity: '819',
    unlikedQuantity: '126',
    notTodayQuantity: '636',
    notForeverQuantity: 39,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '13.190,40',
    mostRecommendedTitles: [
      { title: 'O Milagre na Cela 7', enable: true },
      { title: 'Flesh and Blood: Um Crime Na Vizinhança', enable: true },
      { title: 'Expresso do Amanhã', enable: true },
      { title: 'O Labirinto', enable: true },
      { title: 'Iris', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Julieta', enable: true },
      { title: 'Me chame pelo seu nome', enable: true },
      { title: 'Shirley', enable: true },
      { title: 'Departure - 1ª Temporada', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Million Little Things', enable: true },
      { title: 'Assédio', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: 'Me chame pelo seu nome', enable: true },
      { title: 'Dunkirk', enable: true },
      { title: 'Expresso do Amanhã ', enable: true },
      { title: '11/9 - A Vida sob Ataque', enable: true },
      { title: 'Amorteamo', enable: false },
      { title: 'Framing Britney Spears', enable: true },
    ],
    charts: {
      hex: '#fb1f1f',
      rgb: '251,31,31',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [0, 45, 162, 240, 417, 495, 576]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Documentário', 'Ação'],
        values: [63,18,9,5,3]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Oscar', 'Cannes', 'Familia', 'Dramedia', 'Livro', 'Lgbt', 'Real', 'Violência', 'Independente'],
        values: [8, 7, 6, 6, 5, 5, 5, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [7, 5]
      },
      interestChart: {
        titles: ['Demais Players', 'Globoplay'],
        values: {
          approved: [35, 30],
          notapproved: [6, 5],
          standby: [59, 65]
        }
      }
    }
  },
  disney: {
    id: 202,
    comercialName: 'Disney+',
    price: 27.9,
    moviePercent: 6,
    seriePercent: 3,
    usersQuantity: '717',
    usersReturn: 69, //falta
    recommendationsQuantity: '25.040',
    likedQuantity: '522',
    unlikedQuantity: '48',
    notTodayQuantity: '180',
    notForeverQuantity: 30,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '20.004,30',
    mostRecommendedTitles: [
      { title: 'A Melhor Ideia', enable: true },
      { title: 'Viúva Negra', enable: true },
      { title: 'Milagre do Rio Hudson', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A História da Pixar', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'Império dos Sonhos', enable: true },
      { title: 'Amor, Sublime Amor', enable: true },
      { title: 'Cruella', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Vingadores - Ultimato', enable: true },
      { title: 'Dumbo', enable: true },
      { title: 'Toy Story 4', enable: true },
      { title: 'O Rei Leão', enable: true },
      { title: 'WandaVision', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'Viva - A Vida é uma Festa', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'The Mandalorian', enable: true },
    ],
    charts: {
      hex: '#64bee7',
      rgb: '100,190,231',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [0, 63, 201, 291, 522, 612, 717]
      },
      genresChart: {
        titles: ['Ação','Animação', 'Documentário', 'Drama'],
        values: [60,31,7,2]
      },
      subgenresChart: {
        titles: ['Marvel', 'Heroi', 'Disney', 'Fantasia', 'Oscar', 'Aventura', 'Nostalgia', 'Perseguicao', 'Tecnologia', 'Real'],
        values: [15, 14, 12, 9, 8, 8, 4, 4, 3, 2]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 3]
      },
      interestChart: {
        titles: ['Demais Players', 'Disney+'],
        values: {
          approved: [35, 51],
          notapproved: [6, 5],
          standby: [59, 44]
        }
      }
    }
  },
  star: {
    id: 213,
    comercialName: 'Star+',
    price: 32.9,
    moviePercent: 7,
    seriePercent: 5,
    usersQuantity: '618',
    usersReturn: 69, //falta
    recommendationsQuantity: '53.234',
    likedQuantity: '1164',
    unlikedQuantity: '147',
    notTodayQuantity: '627',
    notForeverQuantity: 51,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '20.332,20',
    mostRecommendedTitles: [
      { title: 'A Cabana', enable: true },
      { title: 'Três Anúncios para um Crime', enable: true },
      { title: 'Her', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'Only Murders in the Building', enable: true },
      { title: 'Jojo Rabbit', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Memórias do 11 de Setembro', enable: true },
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Jogo do Dinheiro', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Bohemian Rhapsody', enable: true },
      { title: 'Her', enable: true },
      { title: 'This is Us', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'A Favorita', enable: true },
      { title: 'Vidro', enable: true },
      { title: 'Mercado de Capitais', enable: true },
      { title: 'Ford vs Ferrari', enable: true },
      { title: 'Les Miserables', enable: true },
    ],
    charts: {
      hex: '#c40f60',
      rgb: '196,15,96',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [0, 45, 183, 255, 453, 525, 618]
      },
      genresChart: {
        titles: ['Drama', 'Ação', 'Ficção', 'Comédia', 'Documentário'],
        values: [57,20,12,7,2]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Oscar', 'Familia', 'Real', 'Heroi', 'Livro', 'Música', 'Shyamalan', 'Tecnologia', 'Casal'],
        values: [13, 10, 6, 5, 5, 5, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [5, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Star+'],
        values: {
          approved: [35, 43],
          notapproved: [6, 6],
          standby: [59, 51]
        }
      }
    }
  },
  googleplay: {
    id: 153,
    comercialName: 'Google Play',
    price: '-',
    moviePercent: 6,
    seriePercent: 0,
    usersQuantity: '162',
    usersReturn: 69, //falta
    recommendationsQuantity: '46.775',
    likedQuantity: '615',
    unlikedQuantity: '96',
    notTodayQuantity: '399',
    notForeverQuantity: 132,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '-',
    mostRecommendedTitles: [
      { title: 'O Apartamento', enable: true },
      { title: 'Amor, Drogas e Nova York', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'Blind', enable: true },
      { title: 'Steve Jobs', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'Noite Passada em Soho', enable: true },
      { title: 'Corpo e Alma', enable: true },
      { title: 'Happy End', enable: true },
      { title: 'Kiki', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Vice', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'Yesterday', enable: true },
      { title: 'Amor ou Consequência', enable: true },
      { title: 'O Homem Duplicado', enable: true },
      { title: 'A Luz entre Oceanos', enable: true },
      { title: 'Kiki', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'O Apartamento', enable: true },
      { title: 'Happy End', enable: true },
    ],
    charts: {
      hex: '#07c263',
      rgb: '7,194,99',
      performance: {
        titles: ['DEZ 2021','JAN 2022', 'FEV 2022', 'MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022'],
        values: [0, 21, 66, 93, 162, 162, 231]
      },
      genresChart: {
        titles: ['Drama', 'Comédia', 'Suspense', 'Ação'],
        values: [73,20,6,1]
      },
      subgenresChart: {
        titles: ['Relacoes','Casal','Independente','Livro','Oscar','Familia','Real','Dramedia','Cannes','Romantica'],
        values: [16, 7, 6, 6, 6, 6, 5, 4, 4, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 3]
      },
      interestChart: {
        titles: ['Demais Players', 'Google Play'],
        values: {
          approved: [35, 24],
          notapproved: [6, 4],
          standby: [59, 72]
        }
      }
    }
  }
};