// 02/2023
export const TwentyTwentyThreeFebruary = {
  viureview: {
    totalUsersQuantity: '206.173', // André
    moviePercent: 70,
    seriePercent: 30,
    usersQuantity: '15.822',
    usersReturn: 73, // André
    recommendationsQuantity: '1.426.760',
    likedQuantity: '38.979',
    unlikedQuantity: '7.296',
    notTodayQuantity: '9.837',
    notForeverQuantity: 1947,
    mostRecommendedTitles: [
      { title: 'Ataque dos Cães (Netflix)', enable: true },
      { title: 'O Caso Collini (Netflix)', enable: true },
      { title: 'Osmosis (Netflix)', enable: true },
      { title: 'Buscando... (Netflix)', enable: true },
      { title: 'Entre Facas e Segredos (Prime Video)', enable: true },
      { title: 'American Son (Netflix)', enable: true },
      { title: '13 Reasons Why (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Areia Movediça (Netflix)', enable: true },
      { title: 'Good Girls (Netflix)', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino (Star+)', enable: true },
      { title: 'Case (Netflix)', enable: true },
      { title: 'Mytho (Netflix)', enable: true },
      { title: 'Se eu não tivesse te conhecido (Netflix)', enable: true },
      { title: '7 anos (Netflix)', enable: true },
      { title: 'Roman J. Israel, Esq. (Netflix)', enable: true },
      { title: 'Acredite em Mim: A História de Lisa McVey (Netflix)', enable: true },
      { title: 'Califado (Netflix)', enable: true },
      { title: 'Creed 2 (Prime Video)', enable: true },
      { title: 'The Sinner - 1ª Temporada (Netflix)', enable: true },
    ],
    charts: {
      hex: '#F36300',
      rgb: '243,99,0',
      performance: { // André
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022','JAN 2023', 'FEV 2023'],
        values: [134682, 140055, 146137, 151198, 156845, 162767, 169752, 175281, 182088, 187931, 196814, 206173]
      },
      genresChart: {
        titles: ['Drama','Ação','Suspense','Ficção','Comédia'],
        values: [35,18,17,16,9]
      },
      subgenresChart: {
        titles: ['Relacoes','Crime','Investigacao','Livro','Psicologico','Real','Europa','Tecnologia','Familia','Oscar'],
        values: [8,7,6,5,5,4,4,4,3,3]
      },
      usersByStreaming: {
        titles: ['NETFLIX', 'PRIME VIDEO', 'HBO Max', 'TELECINE', 'APPLE TV', 'FILME FILME', 'DISNEY+', 'STAR+', 'GLOBOPLAY', 'GOOGLE PLAY'],
        values: [82, 60, 31, 20, 19, 11, 11, 10, 9, 4]
      }
    }
  },
  netflix: {
    id: 27,
    comercialName: 'Netflix',
    price: 39.9,
    moviePercent: 30,
    seriePercent: 48,
    usersQuantity: '13.302',
    usersReturn: 69, //falta
    recommendationsQuantity: '584.221',
    likedQuantity: '23.277',
    unlikedQuantity: '4.641',
    notTodayQuantity: '2.100',
    notForeverQuantity: 867,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '530.749,80',
    mostRecommendedTitles: [
      { title: 'Ataque dos Cães', enable: true },
      { title: 'O Caso Collini', enable: true },
      { title: 'Osmosis', enable: true },
      { title: 'Buscando...', enable: true },
      { title: 'Entre Facas e Segredos', enable: true },
      { title: '13 Reasons Why', enable: true },
      { title: 'American Son', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Areia Movediça', enable: true },
      { title: 'Good Girls', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Case', enable: true },
      { title: 'Mytho', enable: true },
      { title: 'Se eu não tivesse te conhecido', enable: true },
      { title: '7 anos', enable: true },
      { title: 'Roman J. Israel, Esq.', enable: true },
      { title: 'Acredite em Mim: A História Lisa McVey', enable: true },
      { title: 'Califado', enable: true },
      { title: 'The Sinner - 1ª Temporada', enable: true },
      { title: 'Bordertown', enable: true },
      { title: 'Era uma Segunda Vez', enable: true },
    ],
    charts: {
      hex: '#cf1753',
      rgb: '184,25,77',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [11349, 11706, 11802, 11949, 12228, 12507, 12612, 12765, 12900, 13035, 13170, 13302]
      },
      genresChart: {
        titles: ['Drama','Ficção', 'Suspense','Ação','Comédia'],
        values: [28,21,19,17,9]
      },
      subgenresChart: {
        titles: ['Crime', 'Investigação','Relacoes','Livro','Psicologico','Europa','Tecnologia','Real','Perseguicao', 'Espanha'],
        values: [9, 8, 7, 6, 5, 5, 4, 3, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [41, 48]
      },
      interestChart: {
        titles: ['Demais Players', 'Netflix'],
        values: {
          approved: [33, 35],
          notapproved: [5, 7],
          standby: [62, 58]
        }
      }
    }
  },
  telecine: {
    id: 201,
    comercialName: 'Telecine',
    price: 37.9,
    moviePercent: 3,
    seriePercent: 0,
    usersQuantity: '3.324',
    usersReturn: 78, //falta
    recommendationsQuantity: '25.888',
    likedQuantity: '1.215',
    unlikedQuantity: '261',
    notTodayQuantity: '435',
    notForeverQuantity: 69,
    retentionRate: '100',
    churn: 0,
    mrr: '125.979,60',
    mostRecommendedTitles: [
      { title: 'Corra', enable: true },
      { title: 'Luta por Justiça', enable: true },
      { title: 'Em Defesa de Cristo', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'Noite Passada em Soho', enable: true },
      { title: 'O Escândalo', enable: true },
      { title: 'Magnatas do Crime ', enable: true },
      { title: 'Belfast', enable: true },
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'Midsommar', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Utoya 22 de Julho', enable: true },
      { title: 'O Escândalo', enable: true },
      { title: 'Mãe!', enable: true },
      { title: 'Luta por Justiça', enable: true },
      { title: 'Midsommar', enable: true },
      { title: 'Hebe - A estrela do Brasil', enable: true },
      { title: 'Em Defesa de Cristo', enable: true },
      { title: 'Corra', enable: true },
      { title: 'A Esposa', enable: true },
      { title: 'Magnatas do Crime', enable: true },
    ],
    charts: {
      hex: '#4a70a9',
      rgb: '74,112,169',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [2490, 2577, 2626, 2649, 2776, 2904, 2982, 3048, 3117, 3186, 3255, 3324]
      },
      genresChart: {
        titles: ['Suspense', 'Drama','Ação'],
        values: [53,34,13]
      },
      subgenresChart: {
        titles: ['Psicologico', 'Religiao', 'Livro', 'Relacoes', 'Violencia', 'Casal', 'Real', 'Aronofsky', 'Investigacao', 'Sobrenatural'],
        values: [11, 10, 9, 8, 8, 7, 5, 5, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 2]
      },
      interestChart: {
        titles: ['Demais Players', 'Telecine'],
        values: {
          approved: [34, 36],
          notapproved: [6, 9],
          standby: [60, 55]
        }
      }
    }
  },
  apple: {
    id: 59,
    comercialName: 'Apple TV',
    price: 14.9,
    moviePercent: 8,
    seriePercent: 8,
    usersQuantity: '3.171',
    usersReturn: 78, //falta
    recommendationsQuantity: '100.821',
    likedQuantity: '1272',
    unlikedQuantity: '219',
    notTodayQuantity: '1314',
    notForeverQuantity: 138,
    retentionRate: '100',
    churn: 0,
    mrr: '47.247,90',
    mostRecommendedTitles: [
      { title: 'Calls', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Em defesa de Jacob', enable: true },
      { title: 'Losing Alice', enable: true },
      { title: 'Três Estranhos Idênticos', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'O Canto do Cisne', enable: true },
      { title: 'Palmer', enable: true },
      { title: 'Verdade seja dita', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Morning Show', enable: true },
      { title: 'For All Mankind', enable: true },
      { title: 'Servant', enable: true },
      { title: 'Infiltrado na Klan', enable: true },
      { title: 'Verdade seja dita', enable: true },
      { title: 'Todos já sabem', enable: true },
      { title: 'See', enable: true },
      { title: 'Azul é a cor mais quente', enable: true },
      { title: 'Ted Bundy: A Irresistível Face do Mal', enable: true },
      { title: 'Kursk', enable: true },
    ],
    charts: {
      hex: '#c4c4c4',
      rgb: '196,196,196',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [2286, 2397, 2457, 2508, 2638, 2769, 2846, 2901, 2969, 3037, 3105, 3171]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Suspense','Ação'],
        values: [74,9,7,4,3]
      },
      subgenresChart: {
        titles: ['Relações','Casal','Investigação','Crime','Independente','Cannes','Familia','Real','Livro','Europa'],
        values: [13, 10, 7, 6, 6, 6, 6, 6, 5, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [8, 7]
      },
      interestChart: {
        titles: ['Demais Players', 'Apple TV'],
        values: {
          approved: [35, 23],
          notapproved: [6, 3],
          standby: [59, 74]
        }
      }
    }
  },
  prime: {
    id: 37,
    comercialName: 'Prime Video',
    price: 14.9,
    moviePercent: 21,
    seriePercent: 9,
    usersQuantity: '9.771',
    usersReturn: 78, //falta
    recommendationsQuantity: '245.924',
    likedQuantity: '4.989',
    unlikedQuantity: '774',
    notTodayQuantity: '750',
    notForeverQuantity: 315,
    retentionRate: '100',
    churn: 0,
    mrr: '145.587,90',
    mostRecommendedTitles: [
      { title: 'A Grande Mentira', enable: true },
      { title: 'DOM', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'Capote', enable: true },
      { title: 'Ex-Machina', enable: true },
      { title: 'O Passado', enable: true },
      { title: 'Precisamos falar sobre o Kevin!', enable: true },
      { title: 'Sou sua Mulher', enable: true },
      { title: 'Uma razão para viver', enable: true },
      { title: 'A Grande Jogada', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Creed 2', enable: true },
      { title: 'Blue Valentine', enable: true },
      { title: 'Green Book', enable: true },
      { title: 'Vice', enable: true },
      { title: 'As Golpistas', enable: true },
      { title: 'The Boys', enable: true },
      { title: 'Dor e Glória', enable: true },
      { title: 'Verdade e Justiça', enable: true },
      { title: 'O Caso Richard Jewell', enable: true },
      { title: 'Anna', enable: true },
    ],
    charts: {
      hex: '#18a0c8',
      rgb: '24,160,200',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [8103, 8418, 8514, 8619, 8872, 9126, 9189, 9333, 9443, 9553, 9663, 9771]
      },
      genresChart: {
        titles: ['Drama','Ação','Ficção','Suspense','Comédia'],
        values: [38,29,13,10,9]
      },
      subgenresChart: {
        titles: ['Relações','Tecnologia','Real','Oscar','Psicológico','Crime','Investigação','Livro','Família','Terrorismo'],
        values: [11,7,6,6,5,5,4,4,4,3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [18, 12]
      },
      interestChart: {
        titles: ['Demais Players', 'Prime Video'],
        values: {
          approved: [34, 35],
          notapproved: [7, 5],
          standby: [59, 60]
        }
      }
    }
  },
  hbo: {
    id: 36,
    comercialName: 'HBO Max',
    price: 27.9,
    moviePercent: 14,
    seriePercent: 14,
    usersQuantity: '5.025',
    usersReturn: 78, //falta
    recommendationsQuantity: '161.413',
    likedQuantity: '2.328',
    unlikedQuantity: '372',
    notTodayQuantity: '810',
    notForeverQuantity: 135,
    retentionRate: '100',
    churn: 0,
    mrr: '140.197,50',
    mostRecommendedTitles: [
      { title: 'Rainhas do Crime', enable: true },
      { title: 'The Undoing', enable: true },
      { title: 'Relatos Selvagens', enable: true },
      { title: 'Mare of Easttown', enable: true },
      { title: '3096 Dias', enable: true },
      { title: 'Caminhos da Memória', enable: true },
      { title: 'Florida Project', enable: true },
      { title: 'O Pintassilgo', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'Mrs. Fletcher', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'The Jinx: The Life and Deaths of Robert Durst', enable: true },
      { title: 'Big Little Lies', enable: true },
      { title: 'Sharp Objects', enable: true },
      { title: 'A Vida Depois ', enable: true },
      { title: 'O Primeiro Milhão', enable: true },
      { title: 'A Batalha das Correntes', enable: false },
      { title: 'Euphoria', enable: true },
      { title: 'Coringa ', enable: true },
      { title: 'Nasce uma Estrela', enable: true },
      { title: 'Chernobyl ', enable: true },
    ],
    charts: {
      hex: '#a17dc8',
      rgb: '161,125,200',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [3564, 3780, 3849, 3954, 4183, 4413, 4504, 4614, 4717, 4820, 4923, 5025]
      },
      genresChart: {
        titles: ['Drama', 'Suspense','Ação','Documentário', 'Comédia'],
        values: [50,23,12,7,4]
      },
      subgenresChart: {
        titles: ['Real', 'Livro', 'Relacoes', 'Crime', 'Investigacao', 'Sobrenatural', 'Familia', 'Oscar', 'Psicologico', 'Religiao'],
        values: [8, 8, 6, 6, 5, 5, 5, 4, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [12, 12]
      },
      interestChart: {
        titles: ['Demais Players', 'HBO Max'],
        values: {
          approved: [34, 32],
          notapproved: [6, 6],
          standby: [60, 62]
        }
      }
    }
  },
  filmefilme: {
    id: 200,
    comercialName: 'Filme Filme',
    price: 10,
    moviePercent: 3,
    seriePercent: 0,
    usersQuantity: '1.824',
    usersReturn: 78, //falta
    recommendationsQuantity: '19.038',
    likedQuantity: '126',
    unlikedQuantity: '39',
    notTodayQuantity: '111',
    notForeverQuantity: 0,
    retentionRate: '100',
    churn: 0,
    mrr: '18.240,00',
    mostRecommendedTitles: [
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Dafne', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Julho Agosto', enable: true },
      { title: '10.000 KM', enable: true },
      { title: 'A Arte de Amar', enable: true },
      { title: 'Mil Vezes Boa Noite', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'O Último Amor de Mr. Morgan', enable: true },
      { title: 'Tese sobre um Homicídio', enable: true },
      { title: 'A Delicadeza do Amor', enable: true },
      { title: 'Dafne', enable: true },
      { title: 'Café de Flore', enable: true },
      { title: 'A Casa que Jack Construiu', enable: true },
      { title: 'Jovem e Bela', enable: true },
      { title: '10.000 Km', enable: true },
      { title: 'Testemunha Invisível', enable: false },
      { title: 'Julho Agosto', enable: true },
    ],
    charts: {
      hex: '#dd930d',
      rgb: '221,147,13',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [1227, 1281, 1314, 1338, 1428, 1518, 1589, 1614, 1667, 1720, 1773, 1824]
      },
      genresChart: {
        titles: ['Drama','Comédia', 'Suspense'],
        values: [66,18,16]
      },
      subgenresChart: {
        titles: ['Relacoes','Europa','Familia','Independente','Casal','Crime','Italia','Dramedia','Franca','Quentin-tarantino'],
        values: [10, 10, 7, 6, 6, 5, 5, 4, 4, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [1, 1]
      },
      interestChart: {
        titles: ['Demais Players', 'Filme Filme'],
        values: {
          approved: [34, 28],
          notapproved: [6, 6],
          standby: [60, 66]
        }
      }
    }
  },
  globoplay: {
    id: 29,
    comercialName: 'Globoplay',
    price: 24.9,
    moviePercent: 4,
    seriePercent: 9,
    usersQuantity: '1.467',
    usersReturn: 69, //falta
    recommendationsQuantity: '62.632',
    likedQuantity: '636',
    unlikedQuantity: '93',
    notTodayQuantity: '549',
    notForeverQuantity: 21,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '36.528,30',
    mostRecommendedTitles: [
      { title: 'O Milagre na Cela 7', enable: true },
      { title: 'Flesh and Blood: Um Crime Na Vizinhança', enable: true },
      { title: 'Expresso do Amanhã', enable: true },
      { title: 'Iris', enable: true },
      { title: 'O Labirinto', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Shirley', enable: true },
      { title: 'Departure - 1ª Temporada', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: 'A Million Little Things', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Por que as Mulheres Matam', enable: true },
      { title: 'A Million Little Things', enable: true },
      { title: 'Assédio', enable: true },
      { title: 'Onde nascem os fortes', enable: true },
      { title: 'Cidade Proibida', enable: true },
      { title: '11/9 - A Vida sob Ataque', enable: true },
      { title: 'Expresso do Amanhã ', enable: true },
      { title: 'Nudes', enable: true },
      { title: 'O Milagre na Cela 7', enable: true },
      { title: 'Flesh and Blood: Um Crime Na Vizinhança', enable: false },
    ],
    charts: {
      hex: '#fb1f1f',
      rgb: '251,31,31',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [240, 417, 495, 576, 768, 960, 1038, 1125, 1211, 1297, 1383, 1467]
      },
      genresChart: {
        titles: ['Drama','Ficção','Comédia','Documentário', 'Ação'],
        values: [47,25,14,11,2]
      },
      subgenresChart: {
        titles: ['Relacoes','Dramedia','Livro','Violencia','Politico','Real','Asia','Coreia','Cannes','Familia'],
        values: [8, 7, 7, 5, 5, 4, 4, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [5, 5]
      },
      interestChart: {
        titles: ['Demais Players', 'Globoplay'],
        values: {
          approved: [35, 24],
          notapproved: [6, 4],
          standby: [59, 72]
        }
      }
    }
  },
  disney: {
    id: 202,
    comercialName: 'Disney+',
    price: 27.9,
    moviePercent: 5,
    seriePercent: 2,
    usersQuantity: '1.839',
    usersReturn: 69, //falta
    recommendationsQuantity: '29.509',
    likedQuantity: '819',
    unlikedQuantity: '69',
    notTodayQuantity: '168',
    notForeverQuantity: 33,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '51.308,10',
    mostRecommendedTitles: [
      { title: 'A Melhor Ideia', enable: true },
      { title: 'Viúva Negra', enable: true },
      { title: 'Milagre do Rio Hudson', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A História da Pixar', enable: true },
      { title: 'A História do Imagineering', enable: true },
      { title: 'Império dos Sonhos', enable: true },
      { title: 'Amor, Sublime Amor', enable: true },
      { title: 'Cruella', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Homem-Aranha no Aranhaverso', enable: true },
      { title: 'Vingadores - Ultimato', enable: true },
      { title: 'Dumbo', enable: true },
      { title: 'Toy Story 4', enable: true },
      { title: 'O Rei Leão', enable: true },
      { title: 'WandaVision', enable: true },
      { title: 'Pantera Negra', enable: true },
      { title: 'A Bela e a Fera', enable: true },
      { title: 'Viva - A Vida é uma Festa', enable: true },
      { title: 'Estrada para a Glória', enable: true },
    ],
    charts: {
      hex: '#64bee7',
      rgb: '100,190,231',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [291, 522, 612, 717, 952, 1188, 1248, 1395, 1506, 1617, 1728, 1839]
      },
      genresChart: {
        titles: ['Ação','Animação', 'Documentário', 'Drama'],
        values: [62,27,9,2]
      },
      subgenresChart: {
        titles: ['Marvel', 'Heroi', 'Disney', 'Fantasia', 'Oscar', 'Aventura', 'Perseguicao', 'Tecnologia', 'Nostalgia', 'Real'],
        values: [16, 16, 11, 7, 7, 7, 4, 4, 3, 3]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [2, 3]
      },
      interestChart: {
        titles: ['Demais Players', 'Disney+'],
        values: {
          approved: [34, 51],
          notapproved: [6, 5],
          standby: [60, 44]
        }
      }
    }
  },
  star: {
    id: 213,
    comercialName: 'Star+',
    price: 32.9,
    moviePercent: 7,
    seriePercent: 9,
    usersQuantity: '1.602',
    usersReturn: 69, //falta
    recommendationsQuantity: '73.908',
    likedQuantity: '2.178',
    unlikedQuantity: '378',
    notTodayQuantity: '936',
    notForeverQuantity: 87,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '52.705,80',
    mostRecommendedTitles: [
      { title: 'A Cabana', enable: true },
      { title: 'Três Anúncios para um Crime', enable: true },
      { title: 'Projeto Gemini', enable: true },
      { title: 'Only Murders in the Building', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'Jojo Rabbit', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Memórias do 11 de Setembro', enable: true },
      { title: 'Nerve', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'A Linha Vermelha do Destino', enable: true },
      { title: 'Bohemian Rhapsody', enable: true },
      { title: 'This is Us', enable: true },
      { title: 'O Castelo de Vidro', enable: true },
      { title: 'A Favorita', enable: true },
      { title: 'Vidro', enable: true },
      { title: 'Frankie', enable: true },
      { title: 'Projeto Gemini', enable: true },
      { title: 'Les Miserables', enable: true },
      { title: 'Ford vs Ferrari', enable: true },
    ],
    charts: {
      hex: '#c40f60',
      rgb: '196,15,96',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [255, 453, 525, 618, 835, 1053, 1142, 1230, 1323, 1416, 1509, 1602]
      },
      genresChart: {
        titles: ['Drama', 'Ação', 'Comédia', 'Suspense', 'Documentario'],
        values: [61, 21, 8, 7, 4]
      },
      subgenresChart: {
        titles: ['Relacoes','Real','Heroi','Oscar','Familia','Shyamalan','Livro','Marvel','Musica','Crime'],
        values: [11, 7, 6, 6, 6, 5, 5, 5, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [5, 6]
      },
      interestChart: {
        titles: ['Demais Players', 'Star+'],
        values: {
          approved: [34, 40],
          notapproved: [6, 5],
          standby: [60, 55]
        }
      }
    }
  },
  googleplay: {
    id: 153,
    comercialName: 'Google Play',
    price: '-',
    moviePercent: 6,
    seriePercent: 0,
    usersQuantity: '714',
    usersReturn: 69, //falta
    recommendationsQuantity: '61.796',
    likedQuantity: '1.293',
    unlikedQuantity: '309',
    notTodayQuantity: '1.395',
    notForeverQuantity: 132,
    retentionRate: '100', // André
    churn: 0, // André
    mrr: '-',
    mostRecommendedTitles: [
      { title: 'O Apartamento', enable: true },
      { title: 'Amor, Drogas e Nova York', enable: true },
      { title: 'Lady Bird', enable: true },
      { title: 'Blind', enable: true },
      { title: 'Julieta', enable: true },
      { title: 'O Preço da Verdade', enable: true },
      { title: 'Steve Jobs', enable: true },
      { title: 'Me chame pelo seu nome', enable: true },
      { title: 'Corpo e Alma', enable: true },
      { title: 'Kiki', enable: true },
    ],
    mostInterestedTitles: [
      { title: 'Parasita', enable: true },
      { title: 'Bata antes de Entrar', enable: true },
      { title: '1917', enable: true },
      { title: 'Yesterday', enable: true },
      { title: 'Mercado de Capitais', enable: true },
      { title: 'Amor ou Consequência', enable: true },
      { title: 'Me chame pelo seu nome', enable: true },
      { title: 'O Homem Duplicado', enable: true },
      { title: 'Dunkirk', enable: true },
      { title: 'A Luz entre Oceanos', enable: true },
    ],
    charts: {
      hex: '#07c263',
      rgb: '7,194,99',
      performance: {
        titles: ['MAR 2022', 'ABR 2022', 'MAI 2022', 'JUN 2022', 'JUL 2022', 'AGO 2022', 'SET 2022', 'OUT 2022', 'NOV 2022', 'DEZ 2022', 'JAN 2023', 'FEV 2023'],
        values: [93, 162, 162, 231, 271, 411, 456, 501, 555, 609, 663, 714],
      },
      genresChart: {
        titles: ['Drama', 'Suspense', 'Ficção', 'Comédia', 'Ação'],
        values: [51, 24, 14, 11, 1]
      },
      subgenresChart: {
        titles: ['Relacoes', 'Oscar', 'Casal', 'Independente', 'Familia', 'Psicologico', 'Violencia', 'Assedio', 'Europa', 'Cannes'],
        values: [14, 8, 7, 6, 6, 5, 5, 4, 4, 4]
      },
      awarenessRevelanceChart: {
        titles: ["Exposição", "Interesse"],
        values: [4, 4]
      },
      interestChart: {
        titles: ['Demais Players', 'Google Play'],
        values: {
          approved: [34, 31],
          notapproved: [6, 7],
          standby: [60, 62]
        }
      }
    }
  }
};